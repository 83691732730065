import React from 'react';
import { NoResult } from '@flowardco/fui-no-result';
import { useRouteError } from 'react-router-dom';
import { useLocalTranslator } from '@/hooks/useLocalTranslator';
import IconLoadingSpinner from '@flowardco/fui-icons/src/IconLoadingSpinner';

function NotFoundPage() {
  const error: any = useRouteError();
  const { __T } = useLocalTranslator();
  return (
    <div>
      <NoResult
        className='fui-h-full fui-flex-1 !fui-justify-between'
        icon={
          <IconLoadingSpinner className='fui-m-1 fui-mb-4 fui-mt-16 fui-w-8 fui-animate-spin fui-text-salmon-500 md:fui-mt-28' />
        }
        text={__T(error?.message || 'Something Went wrong')}
      />
    </div>
  );
}

export default NotFoundPage;
