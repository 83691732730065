import { MenuList } from '@/components/MenuList';
import { PageTitle } from '@/components/PageTitle';
import { useLocalTranslator } from '@/hooks/useLocalTranslator';

const Homepage = () => {
  const { __T } = useLocalTranslator();
  return (
    <div>
      <PageTitle title={__T('Need Help?')} />
      <div className='fui-block fui-p-4 fui-pt-2'>
        <MenuList />
      </div>
    </div>
  );
};

export default Homepage;
