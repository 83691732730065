import React from 'react';
import cx from 'clsx';
import ReactCalendar from 'react-calendar';
import type { CalendarProps } from 'react-calendar';
import './Calendar.css';
import IconChevronLeft from '@flowardco/fui-icons/src/IconChevronLeft';
import IconChevronRight from '@flowardco/fui-icons/src/IconChevronRight';

export type dayFormat = 'long' | 'short' | 'narrow' | undefined;

export interface CalendarCompProps extends CalendarProps {
  weekdayFormat?: dayFormat;
  arWeekdayFormat?: dayFormat;
  wrapperClassName?: string;
}

export const Calendar: React.FC<CalendarCompProps> = ({
  weekdayFormat = 'short',
  arWeekdayFormat = 'long',
  onChange,
  value,
  className = '',
  wrapperClassName = '',
  ...compProps
}) => {
  return (
    <div
      className={cx('fui-max-w-full fui-pb-4', wrapperClassName)}
      data-testid='TestId__Calendar'
    >
      <ReactCalendar
        onChange={onChange}
        value={value}
        formatShortWeekday={(locale, date) =>
          date.toLocaleString(locale, {
            weekday: locale === 'ar' ? arWeekdayFormat : weekdayFormat,
          })
        }
        calendarType='Hebrew'
        nextLabel={
          <IconChevronRight
            height={16}
            width={16}
            className={cx(
              'rtl:fui-rotate-180',
              'fui-mx-auto fui-text-gray-825'
            )}
          />
        }
        prevLabel={
          <IconChevronLeft
            height={16}
            width={16}
            className={cx(
              'rtl:fui-rotate-180',
              'fui-mx-auto fui-text-gray-825'
            )}
          />
        }
        next2Label={null}
        prev2Label={null}
        className={cx(
          'v2Calender fui-rounded-2xl fui-border fui-border-gray-300',
          className
        )}
        {...compProps}
      />
    </div>
  );
};
