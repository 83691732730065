import { useLocalTranslator } from '@/hooks/useLocalTranslator';
import IconCalendarNew from '@flowardco/fui-icons/src/IconCalendarNew';
import IconClock from '@flowardco/fui-icons/src/IconClock';
import { IconHeading } from '@flowardco/fui-icon-heading';
import { useQuery } from 'react-query';
import { useEffect, useState } from 'react';
import {
  DeliveryDate,
  DeliverySlots as DeliverySlot,
} from '@flowardco/fui-delivery-date';
import { AppLoader } from '@flowardco/app-shared/components/AppLoader';
import {
  getDeliveryDates,
  getDeliverySlots,
  saveDeliveryDateInfo,
} from '@/api/delivery.service';
import { useLocalApp } from '@/hooks/useLocalApp';
import { useNavigate } from 'react-router-dom';
import { PageTitle } from '@/components/PageTitle';
import { Button } from '@flowardco/ui/Button';

const DeliverySlots = () => {
  const { __T, __TK } = useLocalTranslator();
  const { appState } = useLocalApp();
  const [selectedDate, setSelectedDate] = useState<string>('');
  const [selectedSlot, setSelectedSlot] = useState<any>({});
  const [isLoadingSave, setIsLoadingSave] = useState(false);
  const navigate = useNavigate();
  const {
    isLoading: isLoadingDates,

    data: datesList,
  } = useQuery(
    ['datesList', appState?.orderData?.order?.id],
    () => getDeliveryDates(appState, __TK, __T),
    { cacheTime: 1, enabled: selectedDate === '' }
  );
  const { isLoading: isLoadingSlots, data: slotsList } = useQuery(
    ['slotsList', { selectedDate }],
    () => getDeliverySlots(appState, selectedDate, __T),
    {
      cacheTime: 1,
      enabled: selectedDate !== '',
    }
  );

  const onSelectDeliveryDate = (date: any) => {
    setSelectedDate(date);
    setSelectedSlot({});
  };
  const onSelectDeliverySlot = (slot: any) => {
    setSelectedSlot(slot);
  };

  const saveDeliveryDate = async () => {
    if (appState?.orderToken && selectedDate && selectedSlot?.id) {
      const formValue = {
        DeliveryDateTime: selectedDate,
        deliverySlot: selectedSlot?.id,
      };
      setIsLoadingSave(true);
      try {
        await saveDeliveryDateInfo(appState, formValue);
        navigate(`/${appState?.lang || 'en'}`);
      } finally {
        setIsLoadingSave(false);
      }
    }
  };

  useEffect(() => {
    if (datesList?.length > 0 && datesList?.[0]?.webDateString) {
      setSelectedDate(datesList?.[0]?.webDateString);
    }
  }, [datesList]);

  return (
    <>
      <div className='fui-block fui-border-b fui-border-b-salmon-200 '>
        <PageTitle title={__T('Change Delivery Time')} showBackButton />
        {appState?.orderData?.order?.deliveryTimeName ? (
          <div className='fui-block fui-py-2 fui-text-theme-neutral-800'>
            <h3 className='fui-block fui-text-md fui-font-semibold'>
              {__T('Previous Delivery Time')}:
            </h3>
            <p className='fui-dir-ltr fui-py-1 fui-text-md ltr:fui-text-left rtl:fui-text-right'>
              {appState?.orderData?.order?.deliveryTimeName}
            </p>
          </div>
        ) : (
          ''
        )}
      </div>
      <div className='fui-bg-white fui-px-4'>
        {/* Dates */}
        <div className='fui-block fui-pt-4 '>
          <IconHeading
            icon={<IconCalendarNew />}
            title={__T('Select date')}
            iconClass='!fui-w-3'
            titleClass='!fui-font-normal !fui-text-md'
          />
        </div>
        <div className='fui-overflow-hidden  fui-pt-4'>
          {isLoadingDates && <AppLoader />}

          {datesList?.length > 0 && (
            <DeliveryDate
              className='fui-mx-auto fui-mb-3 fui-justify-start'
              deliveryDates={datesList}
              __T={__T}
              onChange={onSelectDeliveryDate}
              labelClassName='fui-font-bold'
              itemClassName='!fui-border-2 !fui-rounded-lg'
            />
          )}
        </div>
        {/* Slots */}
        <div className='fui-block fui-pt-4'>
          <IconHeading
            icon={<IconClock />}
            title={__T('Select time')}
            iconClass='!fui-w-3'
            titleClass='!fui-font-normal !fui-text-md'
          />
        </div>
        <div className='fui-overflow-hidden fui-pt-4'>
          {isLoadingSlots && <AppLoader />}
          <DeliverySlot
            data={slotsList?.avilableSlots}
            onChange={onSelectDeliverySlot}
          />
        </div>
      </div>
      <div className='fui-mt-2 fui-block fui-border-t fui-border-salmon-200 fui-py-4'>
        <Button
          type='button'
          onClick={saveDeliveryDate}
          disabled={!selectedSlot || !selectedSlot?.id || isLoadingSave}
          label={__T('Confirm')}
          className='fui-w-full !fui-rounded fui-text-sm fui-font-bold !fui-uppercase'
        />
      </div>
    </>
  );
};

export default DeliverySlots;
