import { useContext } from 'react';

import {
  LocalAppContext,
  type LocalAppContextProps,
  SET_ORDER_DATA,
} from '@/Context/LocalAppContext';
import { getOrderDetails } from '@/api/order.service';
import { useParams } from 'react-router-dom';
import { errorLoggerV2 } from '@flowardco/app-shared/helpers/ddClientLogs';

export interface AppHookInterface {
  appState: LocalAppContextProps;
  appDispatch: any;
  getOrderData: () => Promise<any>;
}

export const useLocalApp = (): AppHookInterface => {
  const app = useContext(LocalAppContext);
  const { appDispatch, appState } = app;
  const { lang } = useParams();

  const getOrderData = async () => {
    try {
      if (appState?.orderToken && appState?.userToken) {
        const res = await getOrderDetails(appState);
        if (res) {
          appDispatch({
            type: SET_ORDER_DATA,
            payload: {
              lang,
              orderData: res,
            },
          });
        }
      }
    } catch (error: any) {
      errorLoggerV2('Failed Fetching Order Data', error, {
        metaData: {
          action: 'useLocalApp:getOrderData',
        },
      });
    }
  };

  return {
    ...app,
    getOrderData,
  };
};
