import React from 'react';
import cx from 'clsx';
import IconClose from '@flowardco/fui-icons/src/IconClose';
import { useLockBodyScroll } from '@flowardco/flib-hooks';
export interface ModalProps {
  className?: string;
  onIconClose?: () => void;
  onBackdropClose?: () => void;
  containerClass?: string;
  closeBtnClass?: string;
  wrapperClass?: string;
  children?: React.ReactNode;
  v2?: boolean;
  backdropClass?: string;
}

export const Modal: React.FC<ModalProps> = ({
  onIconClose = () => null,
  onBackdropClose = () => null,
  children = null,
  className = 'fui-p-2 md:fui-w-[520px] fui-w-full ',
  containerClass = '',
  closeBtnClass = '',
  wrapperClass = 'fui-z-1000 fui-p-3',
  backdropClass = '',
  v2 = false,
}) => {
  useLockBodyScroll();
  return (
    <div
      data-testid='TestId__ModalWrapper'
      className={cx(
        'fui-fixed fui-left-0 fui-top-0 fui-flex fui-h-full fui-w-full fui-items-start fui-justify-start fui-overflow-auto fui-bg-black-000 fui-bg-opacity-40  fui-align-middle',
        wrapperClass
      )}
    >
      <div
        className={cx(
          'fui-relative fui-z-20 fui-mx-auto fui-my-50px fui-bg-white fui-shadow-down',
          v2 ? ' fui-rounded-lg fui-px-4 fui-py-6' : '',
          className
        )}
        data-testid='TestId__Modal'
      >
        <button
          className={cx(
            `fui-absolute fui-right-0 fui-cursor-pointer rtl:fui-left-0 rtl:fui-right-auto`,
            v2
              ? '!fui-right-4 fui-h-6 fui-w-6 fui-text-navy-500 rtl:!fui-left-4 rtl:!fui-right-auto'
              : '-fui-top-30px fui-h-5 fui-w-5 fui-text-white',
            closeBtnClass
          )}
          onClick={onIconClose}
          data-testid='TestId__CloseBtn'
        >
          <IconClose />
        </button>
        <div
          data-testid='TestId__ModalContainer'
          className={cx('fui-block fui-overflow-auto', containerClass)}
        >
          {children}
        </div>
      </div>
      <div
        onClick={onBackdropClose}
        className={cx(
          'fui-absolute fui-left-0 fui-top-0 fui-z-0 fui-h-full fui-w-full',
          v2 ? ' fui-backdrop-blur-sm' : '',
          backdropClass
        )}
        data-testid='TestId__Backdrop'
      />
    </div>
  );
};
