import cx from 'clsx';
import { getAreaListForOps } from '@/api/address.service';
import { useLocalApp } from '@/hooks/useLocalApp';
import { useLocalTranslator } from '@/hooks/useLocalTranslator';
import { AppErrorBoundary } from '@flowardco/fui-app-error-boundary';
import { FieldErrorMessage, TextareaField } from '@flowardco/fui-input-field';
import { SearchSelect } from '@flowardco/fui-search-select';
import { Controller } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useMapMasterExperimentFlags } from '@flowardco/app-shared/hooks/experiments/useMapMasterExperimentFlags';
import { AddMapButton } from '@flowardco/fui-add-map-button';
import { useState } from 'react';
import { COUNTRY_CODES, OPS_LIST } from '@flowardco/app-shared/lib/constants';
import { APP_CONFIGS } from '@flowardco/flib-helpers';
import { Modal } from '@flowardco/fui-modal';
import { AddressAreaMap } from '@flowardco/fui-address-form-with-map';

export const AddressWithMap = ({
  control,
  setValue,
  register,
  errors,
  getValues,
}: any) => {
  const [showMapModal, setShowMapModal] = useState<boolean>(false);
  const { appState } = useLocalApp();
  const { orderData, lang } = appState;
  const { getGeoLocationEnabledOps } = useMapMasterExperimentFlags();
  const enabledOpsValue = getGeoLocationEnabledOps();
  // Default to KW - Get Ops Lat Lng
  const opsDetails = OPS_LIST.find(
    (ops) => ops.id === orderData?.operation?.id
  ) || { lat: '29.116864', lng: '48.123553' }; // default to KW
  const enableGeoLocation = enabledOpsValue?.some(
    (ops: any) => ops?.opsId === orderData?.operation?.id
  );
  const { __T, __TK } = useLocalTranslator();
  const { data: areasData } = useQuery(
    ['getAreaListForOps'],
    () => {
      return getAreaListForOps({
        __TK,
        opsId: orderData?.operation?.id || 1,
        mapMaster: enableGeoLocation,
      }) as any;
    },
    {
      cacheTime: 60 * 60 * 1000,
      refetchOnWindowFocus: false,
    }
  );

  const { areaNamesList = [], areaNamesFlatList = [] } = areasData || {
    areaNamesList: [],
    areaNamesFlatList: [],
  };

  const handleMapOpen = () => {
    setShowMapModal(true);
  };

  const handleMapClose = () => {
    setShowMapModal(false);
  };

  const handleConfirmMapLocation = async (address: any) => {
    setValue('addressLine', address?.addressLineOne || '', {
      shouldValidate: true,
    });
    setValue('areaId', address?.areaId, {
      shouldValidate: true,
    });
    setValue('lat', address?.lat, {
      shouldValidate: true,
    });
    setValue('lng', address?.lng, {
      shouldValidate: true,
    });
    handleMapClose();
  };

  const handleAreadDropdownChange = (area: any) => {
    setValue('addressLine', '', {
      shouldValidate: true,
    });
    setValue('areaId', area?.value, {
      shouldValidate: true,
    });
  };
  const hasMapError = !!errors?.lat?.message || !!errors?.lng?.message;
  const mapErrorMessage = errors?.lat?.message || errors?.lng?.message || '';
  const selctedAreaName =
    getValues('lat') && getValues('lng')
      ? areaNamesFlatList?.find(
          (item: any) => item?.value === getValues('areaId')
        )?.label
      : '';
  return (
    <div>
      {showMapModal ? (
        <Modal onBackdropClose={handleMapClose} onIconClose={handleMapClose}>
          <AppErrorBoundary>
            <AddressAreaMap
              apiKey={APP_CONFIGS?.apiKeys?.mapApiKey}
              countryCode={
                COUNTRY_CODES[orderData?.operation?.countryId || 1]?.code
              }
              center={{
                lat: parseFloat(
                  orderData?.orderAddress?.lat || opsDetails?.lat || '0'
                ),
                lng: parseFloat(
                  orderData?.orderAddress?.lng || opsDetails?.lng || '0'
                ),
              }}
              lang={lang}
              countryId={orderData?.operation?.countryId || 1}
              opsId={orderData?.operation?.id || 1}
              currency={
                COUNTRY_CODES[orderData?.operation?.countryId || 1]?.currency
              }
              defaultToMyLocation={true}
              __T={__T}
              __TK={__TK}
              validateDropPin={true}
              onConfirm={handleConfirmMapLocation}
              enableGeoLocation={enableGeoLocation}
              orderId={orderData?.order?.id || ''}
            />
          </AppErrorBoundary>
        </Modal>
      ) : null}
      <AppErrorBoundary>
        <div
          className={cx(
            'fui-mb-2 fui-block',
            hasMapError ? 'fui-rounded-xl fui-border fui-border-error-800' : ''
          )}
        >
          <AddMapButton
            label={__T('Add location on the map')}
            onClick={handleMapOpen}
            areaName={selctedAreaName}
            changeLabel={__T('Change')}
          />
        </div>
        {hasMapError ? (
          <FieldErrorMessage errorMessage={mapErrorMessage} />
        ) : (
          ''
        )}
      </AppErrorBoundary>
      <AppErrorBoundary>
        <Controller
          name='areaId'
          control={control}
          render={({ field, fieldState }) => {
            return (
              <SearchSelect
                label={__T('Area')}
                groupedList={areaNamesList}
                placeHolder={__T('Select area')}
                id='areaId'
                name='areaId'
                required={true}
                onChange={handleAreadDropdownChange}
                errorMessage={fieldState?.error?.message || undefined}
                testId='TestId__AreaId'
                value={field.value || ''}
                onBlur={field.onBlur}
              />
            );
          }}
        />
      </AppErrorBoundary>
      <AppErrorBoundary>
        <TextareaField
          wrapperClassName='fui-mt-4'
          label={__T('Recipient Address')}
          id='addressLine'
          name='addressLine'
          placeholder={__T('Recipient Address')}
          register={register}
          errorMessage={errors?.addressLine?.message || undefined}
          testId='TestId__addressLine'
        />
      </AppErrorBoundary>
    </div>
  );
};
