export enum Language {
  en = 1,
  ar = 0,
}

export const SESSION_KEYS = {
  SESSION_ID: 'SESSION_ID',
  SOURCE: 'SOURCE',
  ORDER_TOKEN_ID: 'ORDER_TOKEN_ID',
};

export enum TicketStatus {
  OPENED = 0,
  WORKING_ON = 1,
  CLOSED = 2,
}
export const REFUND_LOCATIONS = [
  { id: 1, label: 'Floward Wallet' },
  //  'Bank'
];

export const CANCEL_REASONS_LIST = [
  { id: 1, label: 'I want to order something else' },
  { id: 2, label: 'Duplicate Order' },
  { id: 3, label: 'Wrong city' },
  { id: 4, label: 'Changed my mind' },
  { id: 5, label: 'Recipient is not available' },
  { id: 6, label: 'Order is extremely late' },
  { id: -1, label: 'Other' },
];
