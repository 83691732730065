import { GrowthBookProvider, GrowthBook } from '@growthbook/growthbook-react';
import { APP_CONFIGS, cookieStore } from '@flowardco/flib-helpers';
import React, { Fragment, useEffect } from 'react';

const userId = cookieStore.getAbTestUserId();

const gb = new GrowthBook({
  attributes: {
    id: userId,
  },
  apiHost: APP_CONFIGS.growthbook.apiHost,
  clientKey: APP_CONFIGS.growthbook.clientKey,
  enableDevMode: APP_CONFIGS.growthbook.devMode,
});

const ABTestWrapper = ({
  client,
  children,
}: {
  client: any;
  children: React.ReactNode;
}) => {
  if (APP_CONFIGS.growthbook.enabled) {
    return (
      <GrowthBookProvider growthbook={client}>{children}</GrowthBookProvider>
    );
  }
  return <Fragment>{children}</Fragment>;
};

export const ABTestProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  useEffect(() => {
    if (APP_CONFIGS.growthbook.enabled) {
      if (!userId) {
        const id = cookieStore.getAbTestUserId();
        gb.setAttributeOverrides({ id });
      }
      gb.loadFeatures({ autoRefresh: true, timeout: 1500 });
    }
  }, []);

  return <ABTestWrapper client={gb}>{children}</ABTestWrapper>;
};
