import React, { useState, useEffect } from 'react';
import { FieldErrorMessage, InputLabel } from '@flowardco/fui-input-field';
import type { SelectListType } from '@flowardco/fui-input-field';
import cx from 'clsx';
import type { StylesConfig, SingleValue } from 'react-select';
import Select from 'react-select';
import './styles.css';

export type GroupedListType = { label: string; options: SelectListType[] };

export interface SearchSelectProps extends React.ComponentProps<'select'> {
  onChange?: any;
  list?: SelectListType[];
  groupedList?: GroupedListType[];
  placeHolder?: string;
  selectClassName?: string;
  errorMessage?: string;
  value?: string | number;
  disabled?: boolean;
  containerClass?: string;
  label?: string;
  required?: boolean;
  labelClass?: string;
  testId?: string;
}

export const SearchSelect: React.FC<SearchSelectProps> = ({
  id,
  name,
  list,
  groupedList,
  errorMessage,
  placeHolder = 'Select...',
  onChange,
  value,
  disabled,
  containerClass,
  label,
  required,
  labelClass,
  testId = 'TestId__SearchSelect',
}) => {
  const [selected, setSelected] = useState<SingleValue<SelectListType>>();
  const listValues =
    groupedList?.flatMap(({ options }) => options.map((itm: any) => itm)) ||
    list ||
    [];

  useEffect(() => {
    if (value) {
      const selectedItem = listValues.find((item) => item.value === value);
      setSelected(selectedItem);
    }
  }, [value, listValues]);

  const handleChange = (e: SingleValue<SelectListType>) => {
    setSelected(e);
    if (onChange) {
      onChange(e);
    }
  };

  const customStyles: StylesConfig<SelectListType, false> = {
    control: (_, state) => ({
      border: state.isFocused ? '1px solid #006782' : '1px solid #d4d4d4',
      borderColor: errorMessage ? '#de3618' : '',
    }),
  };
  return (
    <div className={containerClass} data-testid={testId}>
      <InputLabel
        id={`select-${id || name}`}
        labelClass={cx('fui-mb-1', labelClass)}
        label={label}
        required={required}
      />
      <Select
        styles={customStyles}
        value={selected}
        placeholder={placeHolder}
        onChange={handleChange}
        options={groupedList ? groupedList : list}
        classNamePrefix='fui-custom-select'
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: '#EBFBFF',
            primary50: '#CDE2E9',
            primary: '#007c90',
          },
        })}
        isDisabled={disabled}
      />
      <FieldErrorMessage
        errorMessage={errorMessage}
        className={cx(errorMessage ? 'fui-mb-2' : '')}
      />
    </div>
  );
};
