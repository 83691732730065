import { errorLoggerV2 } from '@flowardco/app-shared/helpers/ddClientLogs';

import {
  API_ENDPOINTS,
  formatUrlParams,
  formatHttpResponse,
} from '@flowardco/flib-helpers';

import { addRequestHeaders, orderClient } from './api.service';
import type { LocalAppContextProps } from '@/Context/LocalAppContext';
import { addToast } from '@flowardco/flib-util';
import type { CardMessageValues } from '@flowardco/flib-models';
export const getCardData = async (appState: LocalAppContextProps) => {
  try {
    const { orderData, userToken, lang, orderToken } = appState;
    const headers = addRequestHeaders({
      operation: orderData?.operation!,
      userToken,
      lang,
    });
    const res = await orderClient.get(
      formatUrlParams(API_ENDPOINTS.helpCenterApp.cardMessage, {
        orderToken,
      }),
      {
        headers,
      }
    );
    const CardDataData = formatHttpResponse(res);
    return CardDataData;
  } catch (error: any) {
    errorLoggerV2('CardService::getCardData', error);
    return [];
  }
};

export const addCardMessage = async (
  values: CardMessageValues,
  appState: LocalAppContextProps
): Promise<any> => {
  try {
    const { orderData, userToken, lang, orderToken } = appState;
    const headers = addRequestHeaders({
      operation: orderData?.operation!,
      userToken,
      lang,
    });
    const data = await orderClient.post(
      formatUrlParams(API_ENDPOINTS.helpCenterApp.cardMessage, {
        orderToken,
      }),
      values,
      {
        headers,
      }
    );
    const formattedResponse = formatHttpResponse(data);
    if (formattedResponse?.success) {
      addToast(formattedResponse?.message);
    }
    return formattedResponse;
  } catch (error: any) {
    addToast(error?.response?.data?.message, 'error');
    errorLoggerV2('CardService::addCardMessage', error);
    return false;
  }
};
