import { useLocalTranslator } from '@/hooks/useLocalTranslator';
import { Link } from 'react-router-dom';
import IconMapMarker from '@flowardco/fui-icons/src/IconMapMarker';
import IconClock from '@flowardco/fui-icons/src/IconClock';
import IconArrowRight from '@flowardco/fui-icons/src/IconArrowRight';
import IconHeadset from '@flowardco/fui-icons/src/IconHeadset';
import IconClose from '@flowardco/fui-icons/src/IconClose';
import IconEdit from '@flowardco/fui-icons/src/IconEdit';
import IconWarning from '@flowardco/fui-icons/src/IconWarning';
import { useLocalApp } from '@/hooks/useLocalApp';

export const MenuList = () => {
  const { appState } = useLocalApp();
  const { lang, orderData } = appState;
  const menuItems = [
    [
      {
        label: 'Change Delivery Time',
        url: 'delivery-time',
        icon: IconClock,
        showIftrue: 'allowChangeDeliveryTime',
      },

      {
        label: 'Change Delivery Address',
        url: 'delivery-address',
        icon: IconMapMarker,
        showIftrue: 'allowChangeDeliveryAddress',
      },
      {
        label: 'Edit Message Card',
        url: 'edit-card-message',
        icon: IconEdit,
        showIftrue: 'allowEditCard',
      },
    ],
    [
      {
        label: 'Customer Care',
        url: 'customer-care',
        icon: IconHeadset,
        showIftrue: 'allowCustomerCare',
      },
    ],
    [
      {
        label: 'Cancel Order',
        url: 'cancel-order',
        icon: IconClose,
        showIftrue: 'allowCancelStatus',
      },
    ],
    [
      {
        label: 'Issue with my order',
        url: 'issues',
        icon: IconWarning,
        showIftrue: 'allowComplaint',
      },
    ],
  ];

  const { __T } = useLocalTranslator();

  return (
    <div className='fui-mx-auto fui-block fui-w-full fui-max-w-md fui-px-4 fui-pb-4'>
      {menuItems.map((menu, idx) => (
        <ul
          className='fui-mb-6 fui-overflow-hidden fui-rounded-lg fui-bg-white'
          key={`${idx}-menu-main`}
        >
          {menu.map((menuItem, indx) =>
            orderData?.[menuItem?.showIftrue] ||
            (menuItem?.showIftrue === 'allowCustomerCare' &&
              orderData?.order?.id &&
              orderData?.operation?.id) ? (
              <li
                className='fui-block fui-border-b fui-border-theme-neutral-200 last:fui-border-b-0'
                key={`${idx}-${indx}-menu-items`}
              >
                <Link
                  to={`/${lang}/${menuItem.url}`}
                  className='fui-inline-flex fui-w-full fui-items-center fui-justify-start fui-bg-white fui-py-4 fui-align-middle fui-text-base fui-font-medium fui-text-theme-neutral-800 hover:fui-bg-theme-neutral-50 hover:fui-text-theme-neutral-800'
                >
                  <span className='fui-inline-flex fui-items-center fui-justify-start fui-align-middle'>
                    {menuItem.icon && (
                      <menuItem.icon className='fui-mx-2 fui-h-4 fui-w-4' />
                    )}
                  </span>
                  <span className='fui-inline-flex fui-items-center fui-justify-start fui-align-middle'>
                    {__T(menuItem.label)}
                  </span>
                  <span className='fui-inline-flex ltr:fui-ml-auto rtl:fui-mr-auto rtl:fui-rotate-180'>
                    <IconArrowRight className='fui-mx-2 fui-h-3.5 fui-w-3.5' />
                  </span>
                </Link>
              </li>
            ) : null
          )}
        </ul>
      ))}
    </div>
  );
};
