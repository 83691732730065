import { useNavigate } from 'react-router-dom';
import IconArrowLeft from '@flowardco/fui-icons/src/IconArrowLeft';
import { useLocalApp } from '@/hooks/useLocalApp';

export const PageTitle = ({
  title,

  showBackButton = false,
}: {
  title: string;
  showBackButton?: boolean;
}) => {
  const navigate = useNavigate();
  const onGoBack = () => {
    navigate(-1);
  };
  const { appState } = useLocalApp();
  const isFromApp = appState?.isFromApp || false;
  return (
    <header className='fui-flex fui-w-full fui-items-center fui-justify-between fui-align-middle'>
      <div className='fui-block'>
        {!isFromApp && showBackButton ? (
          <button
            type='button'
            className='fui-inline-flex fui-h-7 fui-w-7 fui-appearance-none fui-items-center fui-justify-center fui-rounded-md fui-bg-white fui-p-1 fui-align-middle fui-text-navy-500 fui-shadow-md fui-transition-all fui-duration-200 hover:fui-bg-navy-500 hover:fui-text-white'
            onClick={onGoBack}
          >
            <IconArrowLeft className='fui-h-4 fui-w-4 rtl:fui-rotate-180' />
          </button>
        ) : (
          ''
        )}
      </div>
      <h1 className='page-main-title fui-mx-auto fui-inline-flex fui-w-auto fui-py-4 fui-text-center fui-text-xl fui-font-bold fui-text-theme-neutral-900'>
        {title}
      </h1>
      <div className='fui-block' />
    </header>
  );
};
