import React, { Suspense } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { AppErrorBoundary } from '@flowardco/fui-app-error-boundary';
import { AppRoutes } from '@/routes/AppRoutes';
import { FullPageLoader } from '@flowardco/app-shared/components/AppLoader';
import { ToastComponent } from '@flowardco/fui-toast-component';
import { traceClientLogs } from '@flowardco/app-shared/helpers/ddClientLogs';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ABTestProvider } from '@flowardco/app-shared/components/ABTestProvider';
import { LocalAppContextProvider } from './Context/LocalAppContext';

traceClientLogs('React-Helpcenter', { forwardErrorsToLogs: false });

const queryClient = new QueryClient();
export const App: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <AppErrorBoundary>
        <ABTestProvider>
          <Suspense fallback={<FullPageLoader />}>
            <HelmetProvider>
              <LocalAppContextProvider>
                <AppRoutes />
              </LocalAppContextProvider>
            </HelmetProvider>
          </Suspense>
          <ToastComponent />
        </ABTestProvider>
      </AppErrorBoundary>
    </QueryClientProvider>
  );
};
