import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useQuery } from 'react-query';
import {
  InputField,
  SelectField,
  TextareaField,
} from '@flowardco/fui-input-field';
import { Button } from '@flowardco/ui/Button';
import { PageTitle } from '@/components/PageTitle';
import { useLocalTranslator } from '@/hooks/useLocalTranslator';
import { useValidations } from '@flowardco/flib-hooks';
import { useLocalApp } from '@/hooks/useLocalApp';
import type { ComplaintValues } from '@/api/order-issue.service';
import { getOrderIssues, raiseComplaint } from '@/api/order-issue.service';
import { UploadBox } from '@flowardco/fui-upload-box';

const RaiseComplaint = () => {
  const { __T } = useLocalTranslator();
  const { appState } = useLocalApp();
  const navigate = useNavigate();
  const { validations } = useValidations(__T);

  const { data: orderIssues } = useQuery(
    ['getOrderIssues', appState?.orderData?.order?.id],
    () => getOrderIssues(appState)
  );

  const validationSchema = Yup.object().shape({
    topicId: validations.name,
    title: validations.name,
    description: validations.name,
  });

  const initialValues: ComplaintValues = {
    topicId: '',
    title: '',
    topicName: '',
    description: '',
    mapid: appState?.orderData?.order?.id,
    openedBy: appState?.orderData?.order?.userId,
    openedByName: 'customer',
    opsName: appState?.orderData?.operation?.nickName,
    opsId: appState?.orderData?.operation?.id,
    file1: '',
  };

  const handleSubmit = async (values: ComplaintValues) => {
    const orderIssueData = new FormData();

    Object.entries(values).forEach(([key, value]) => {
      orderIssueData.append(key, value);
    });

    const res = await raiseComplaint(orderIssueData, appState, __T);
    if (res) {
      navigate(`/${appState?.lang || 'en'}`);
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
    validationSchema,
  });

  const handleUploadFile = (e: any) => {
    const name = e.target.name;
    if (e?.target?.files?.[0]) {
      formik.setFieldTouched(name, true, true);
      formik.setFieldValue(name, e.target.files[0]);
    }
  };

  const handleTopicChange = (event: any) => {
    const selectedTopicId = event.target.value;
    const selectedTopic = orderIssues?.find(
      (topic: any) => topic?.id === selectedTopicId
    );
    const selectedTopicName = selectedTopic ? selectedTopic?.label : '';

    formik.setFieldValue('topicId', selectedTopicId);
    formik.setFieldValue('topicName', selectedTopicName);
    formik.setFieldValue('title', selectedTopicName);
  };
  return (
    <div>
      <PageTitle title={__T('Issue with my order')} showBackButton />
      <div className='fui-bg-white fui-p-4'>
        <form onSubmit={formik.handleSubmit}>
          <SelectField
            label={__T('Issues')}
            id='topicId'
            name='topicId'
            blankOption={__T('Select issue')}
            required={true}
            selectClassName='fui-mb-1 fui-w-full'
            containerOverrideClass='fui-w-full fui-mb-3'
            labelClass='fui-whitespace-nowrap'
            list={orderIssues}
            onChange={handleTopicChange}
            onBlur={formik.handleBlur}
            errorMessage={
              formik.errors.topicId && formik.touched.topicId
                ? formik.errors.topicId
                : undefined
            }
            value={formik.values.topicId}
          />

          <InputField
            label={__T('Subject')}
            id='title'
            name='title'
            required={true}
            inputFieldClass='fui-mb-1'
            wrapperClassName='fui-w-full fui-mb-3'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errorMessage={
              formik.errors.title && formik.touched.title
                ? formik.errors.title
                : undefined
            }
            value={formik.values.title}
          />

          <TextareaField
            wrapperClassName='fui-mt-1 md:fui-mt-4 fui-mb-4 md:fui-mb-0'
            name='description'
            id='description'
            required={true}
            className='fui-text-sm'
            label={__T('Ticket details')}
            placeholder={__T('Ticket details*')}
            onChange={formik.handleChange}
            inputProps={{ onBlur: formik.handleBlur }}
            value={formik.values.description}
            errorMessage={
              formik.errors.description && formik.touched.description
                ? formik.errors.description
                : undefined
            }
          />
          {appState?.isFromApp ? (
            ''
          ) : (
            <UploadBox
              id='file1'
              name='file1'
              onChange={handleUploadFile}
              descriptionClass='fui-text-sm'
              className='fui-mb-3 fui-mt-2 fui-text-center'
              buttonLabel={__T('Attach File')}
              accept='image/*'
              errorMessage={
                formik.errors.file1 && formik.touched.file1
                  ? formik.errors.file1
                  : undefined
              }
            />
          )}

          <Button
            buttonStyle='primary'
            label={__T('Add')}
            className='fui-mb-2 fui-mt-3 fui-w-full fui-text-base fui-font-normal'
            type='submit'
            disabled={formik.isSubmitting}
            isProcessing={formik.isSubmitting}
          />
        </form>
      </div>
    </div>
  );
};

export default RaiseComplaint;
