import { useState } from 'react';
import {
  API_ENDPOINTS,
  API_SERVICE_URLS,
  formatHttpError,
  formatHttpResponse,
  http,
} from '@flowardco/flib-helpers';
import type { Coords } from 'google-map-react';

export interface AddressMapAPIHookProps {
  lang: number;
  countryId: number;
  opsId: number;
  currency: string;
}

export interface PostalCodeHookResponseProps {
  getAddressLocation: (location: Coords, placeName?: string) => Promise<any>;
  getAddressGeoLocation: (
    location: Coords,
    placeName?: string,
    orderId?: string | number
  ) => Promise<any>;
  isLoading: boolean;
}

const useAddressMapApi = ({
  lang = 1,
  countryId = 1,
  opsId = 1,
  currency = 'KWD',
}: AddressMapAPIHookProps): PostalCodeHookResponseProps => {
  const [isLoading, setIsLoading] = useState(false);
  const client = http({
    baseURL: API_SERVICE_URLS.ORDER,
    headers: {
      lang,
      OpsCountryID: countryId,
      opsId,
      currency,
    },
  });
  const client2 = http({
    baseURL: API_SERVICE_URLS.API2,
    headers: {
      lang,
      OpsCountryID: countryId,
      opsId,
      currency,
    },
  });

  const getAddressLocation = async (
    location: Coords,
    placeName: string = ''
  ): Promise<any> => {
    setIsLoading(true);
    try {
      const data = await client.get(API_ENDPOINTS.checkLocationMap, {
        params: { ...location, placeName, opsId },
      });
      const formattedResponse = formatHttpResponse(data);
      setIsLoading(false);
      return { ...formattedResponse, isError: false };
    } catch (error: any) {
      setIsLoading(false);
      return {
        isError: true,
        message: formatHttpError(error)?.response?.data?.message || '',
      };
    }
  };

  const getAddressGeoLocation = async (
    location: Coords,
    placeName: string = '',
    orderId: number | string = ''
  ): Promise<any> => {
    setIsLoading(true);
    try {
      const data = await client2.get(API_ENDPOINTS.checkGeoLocationMap, {
        params: { ...location, placeName, opsId, orderId },
      });
      const formattedResponse = formatHttpResponse(data);
      setIsLoading(false);
      return { ...formattedResponse, isError: false };
    } catch (error: any) {
      setIsLoading(false);
      return {
        isError: true,
        message: formatHttpError(error)?.response?.data?.message || '',
      };
    }
  };
  return {
    getAddressLocation,
    isLoading,
    getAddressGeoLocation,
  };
};

export default useAddressMapApi;
