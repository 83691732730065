import cx from 'clsx';
import React from 'react';

export interface DeliverySlotsProps {
  data?: any[];
  onChange: any;
  name?: string;
  selectedSlotId?: string | number;
}

export const DeliverySlots: React.FC<DeliverySlotsProps> = ({
  data = [],
  onChange,
  name = 'deliverySlot',
  selectedSlotId = 0,
}) => {
  return (
    <>
      {data?.length > 0 && (
        <div
          className='fui-flex fui-h-auto fui-w-full fui-flex-col'
          data-testid='TestId__DeliverySlots'
        >
          {data?.map((slot: any, index: number) => {
            return (
              <label
                className={cx(
                  'fui-relative fui-block fui-pb-2',
                  slot?.disabled
                    ? 'fui-cursor-not-allowed'
                    : 'fui-cursor-pointer'
                )}
                key={`${name || 'deliverySlot'}-${slot?.id}-${index}`}
                data-testid={`TestId__DeliverySlotsItem-${slot?.id}-${index}`}
              >
                <input
                  type='radio'
                  className='fui-peer fui-invisible fui-absolute fui-left-0 fui-top-0 fui-h-0 fui-w-0 fui-appearance-none fui-opacity-0'
                  onChange={() => onChange(slot)}
                  id={`${name || 'deliverySlot'}-${slot?.id}-${index}`}
                  name={name}
                  disabled={slot?.disabled || false}
                  defaultChecked={slot?.id === selectedSlotId}
                  data-testid={`TestId__DeliverySlotsItemInput-${slot?.id}-${index}`}
                />
                <div
                  className={cx(
                    'fui-relative fui-inline-flex fui-w-full fui-items-center fui-justify-center fui-rounded-lg fui-border fui-border-gray-550 fui-bg-white fui-px-6 fui-py-4 fui-align-middle fui-text-md fui-font-bold fui-uppercase  peer-checked:fui-border-navy-500',
                    slot?.disabled
                      ? 'fui-border-gray-100 !fui-bg-gray-100 fui-text-gray-450'
                      : 'fui-text-black-200'
                  )}
                >
                  {slot?.statusName ? (
                    <span className='fui-absolute fui-top-0 fui-inline-block fui-px-1 fui-py-0.5 fui-text-md fui-font-bold !fui-normal-case fui-text-gray-300 ltr:fui-left-0 rtl:fui-right-0'>
                      {slot?.statusName}
                    </span>
                  ) : null}
                  <span className={cx('fui-dir-ltr fui-font-english')}>
                    {slot?.name}
                  </span>
                </div>
              </label>
            );
          })}
        </div>
      )}
    </>
  );
};
