import cx from 'clsx';
import { Fragment } from 'react';
interface CancelReasonsListProps {
  reasons: { id: number | string; label: string | undefined }[];
  handleReasonClick: (reason: string) => void;
  __T: any;
  selectedReason: any;
  name?: string;
}

const CancelReasonsList: React.FC<CancelReasonsListProps> = ({
  reasons,
  handleReasonClick,
  __T,
  selectedReason,
  name = 'reasons',
}) => {
  return (
    <div className='fui-mb-3 fui-flex fui-flex-col fui-justify-start'>
      {reasons?.map((reason: any, idx: number) => {
        const isSelected = reason.id === selectedReason?.id;
        return (
          <Fragment key={`${name}-${idx}-${reason}`}>
            <label
              className={cx(
                'fui-relative fui-flex fui-select-none fui-items-center fui-justify-between fui-border-b-2 fui-bg-white fui-p-3 hover:fui-cursor-pointer'
              )}
              htmlFor={`${name}-${reason?.id}`}
            >
              <input
                type='radio'
                name={name}
                checked={isSelected}
                id={`${name}-${reason.id}`}
                onChange={() => handleReasonClick(reason)}
                className='fui-peer fui-invisible fui-absolute fui-opacity-0'
              />
              <span className='fui-inline-flex fui-text-base'>
                {__T(reason.label)}
              </span>
              <span className='fui-inline-flex fui-h-4 fui-w-4 fui-items-center fui-justify-center fui-rounded-[50%] fui-border fui-border-navy-500 fui-align-middle fui-text-transparent peer-checked:fui-bg-navy-500 peer-checked:fui-text-white'></span>
            </label>
          </Fragment>
        );
      })}
    </div>
  );
};

export default CancelReasonsList;
