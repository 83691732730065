import * as React from 'react';
import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

const SvgIconCalendarNew = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox='0 0 14 17'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    ref={ref}
    {...props}
  >
    <path
      d='M4.625 9.39A.38.38 0 0 0 5 9.017v-1.25a.403.403 0 0 0-.375-.375h-1.25A.38.38 0 0 0 3 7.766v1.25c0 .218.156.375.375.375h1.25ZM8 9.017v-1.25a.403.403 0 0 0-.375-.375h-1.25A.38.38 0 0 0 6 7.766v1.25c0 .218.156.375.375.375h1.25A.38.38 0 0 0 8 9.016Zm3 0v-1.25a.403.403 0 0 0-.375-.375h-1.25A.38.38 0 0 0 9 7.766v1.25c0 .218.156.375.375.375h1.25A.38.38 0 0 0 11 9.016Zm-3 3v-1.25a.403.403 0 0 0-.375-.375h-1.25a.38.38 0 0 0-.375.375v1.25c0 .219.156.375.375.375h1.25A.38.38 0 0 0 8 12.016Zm-3 0v-1.25a.403.403 0 0 0-.375-.375h-1.25a.38.38 0 0 0-.375.375v1.25c0 .219.156.375.375.375h1.25A.38.38 0 0 0 5 12.016Zm6 0v-1.25a.403.403 0 0 0-.375-.375h-1.25a.38.38 0 0 0-.375.375v1.25c0 .219.156.375.375.375h1.25a.38.38 0 0 0 .375-.375Zm3-8.125c0-.813-.688-1.5-1.5-1.5H11V.766a.403.403 0 0 0-.375-.375h-1.25A.38.38 0 0 0 9 .766V2.39H5V.766A.403.403 0 0 0 4.625.39h-1.25A.38.38 0 0 0 3 .766V2.39H1.5A1.5 1.5 0 0 0 0 3.89v11c0 .844.656 1.5 1.5 1.5h11a1.5 1.5 0 0 0 1.5-1.5v-11Zm-1.5 10.812c0 .125-.094.188-.188.188H1.688c-.125 0-.188-.063-.188-.188V5.391h11v9.312Z'
      fill='currentColor'
    />
  </svg>
);

const ForwardRef = forwardRef(SvgIconCalendarNew);
export default ForwardRef;
