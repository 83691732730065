import React from 'react';
import cx from 'clsx';
import IconMapMarkerFilled from '@flowardco/fui-icons/src/IconMapMarkerFilled';
import mapPreview from './map-preview-bg.png';

export interface AddMapButtonProps {
  onClick?(event: React.MouseEvent): void;
  className?: string;
  mapImage?: string;
  label?: string;
  disabled?: boolean;
  changeLabel?: string;
  areaName?: string;
}

export const AddMapButton: React.FC<AddMapButtonProps> = ({
  className = '',
  mapImage = mapPreview,
  label = 'Add location on map',
  changeLabel = 'Change',
  areaName = '',
  onClick,
  disabled = false,
}) => {
  return (
    <div
      className={cx('fui-relative', className)}
      data-testid='TestId__AddMapButtonHolder'
    >
      <div
        className={cx(
          'fui-absolute fui-left-0 fui-top-0 fui-z-3 fui-flex fui-h-full fui-w-full fui-items-center fui-bg-white/5 fui-px-2 fui-align-middle',
          areaName ? 'fui-justify-between' : 'fui-justify-center'
        )}
      >
        {areaName ? (
          <span className='fui-inline-flex fui-flex-nowrap fui-items-center fui-justify-center fui-rounded-lg fui-bg-white/50 fui-p-2 fui-align-middle fui-text-base fui-font-bold fui-text-black-100'>
            <span className='fui-inline-flex fui-h-auto fui-w-3.5 fui-text-navy-500'>
              <IconMapMarkerFilled />
            </span>
            <span className='fui-inline-flex fui-px-1'>{areaName}</span>
          </span>
        ) : null}
        <button
          type='button'
          className={cx(
            'fui-inline-flex fui-flex-nowrap fui-items-center fui-justify-center fui-rounded-lg fui-p-2 fui-align-middle fui-text-base fui-font-bold',
            disabled
              ? 'fui-bg-gray-400/50 fui-text-white'
              : 'fui-bg-white/50 fui-text-navy-500'
          )}
          onClick={onClick}
          data-testid='TestId__AddMapButton'
          disabled={disabled}
        >
          {!areaName ? (
            <span className='fui-inline-flex fui-h-auto fui-w-3.5'>
              <IconMapMarkerFilled />
            </span>
          ) : null}
          <span className='fui-inline-flex fui-px-2'>
            {areaName ? changeLabel : label}
          </span>
        </button>
      </div>
      <img
        src={mapImage}
        width={'100%'}
        height={'auto'}
        className={cx(
          'fui-z-0 fui-mx-auto fui-block fui-h-auto fui-w-full fui-rounded-xl fui-drop-shadow-small',
          disabled ? 'fui-opacity-50' : ''
        )}
        alt={'map'}
      />
    </div>
  );
};
