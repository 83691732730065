import React, { useState, useEffect } from 'react';
import cx from 'clsx';
import styles from './Snackbar.module.css';
import IconWarning from '@flowardco/fui-icons/src/IconWarning';
import IconSuccess from '@flowardco/fui-icons/src/IconSuccess';
import IconClose from '@flowardco/fui-icons/src/IconClose';

export type SnackbarType = 'alert' | 'warning' | 'success' | 'info';
export interface SnackbarProps {
  className?: string;
  type?: SnackbarType;
  noteText?: string;
  noteTextClass?: string;
  ableToClose?: boolean;
  delay?: number;
  onIconClose?: () => void;
  onClickNavigateBtn?: () => void;
  withIcon?: boolean;
  iconWarningClass?: string;
  iconSuccessClass?: string;
  buttonTxt?: string;
  buttonUrl?: string;
  children?: React.ReactNode;
}

export const Snackbar: React.FC<SnackbarProps> = ({
  className = '',
  type = 'warning',
  noteText,
  noteTextClass = '',
  ableToClose = false,
  onIconClose,
  onClickNavigateBtn,
  delay,
  withIcon = false,
  iconWarningClass = 'fui-text-gray-775',
  iconSuccessClass = 'fui-text-green-500',
  buttonTxt = '',
  buttonUrl = '',
  children = null,
}) => {
  const [hideDiv, setHideDiv] = useState<boolean>(false);

  const IconType: { [key: string]: React.ReactNode } = {
    warning: (
      <IconWarning
        className={cx(
          'fui-shrink-0 ltr:fui-mr-2.5 rtl:fui-ml-2.5',
          iconWarningClass
        )}
        width={16}
        height={16}
        data-testid='TestId__SnackbarIcon'
      />
    ),
    success: (
      <IconSuccess
        className={cx(
          'fui-shrink-0 ltr:fui-mr-2.5 rtl:fui-ml-2.5',
          iconSuccessClass
        )}
        width={16}
        height={16}
        data-testid='TestId__SnackbarIcon'
      />
    ),
  };

  const handleClose = () => {
    setHideDiv(true);
    if (onIconClose) onIconClose();
  };

  useEffect(() => {
    if (delay) {
      setTimeout(() => {
        setHideDiv(true);
      }, delay);
    }
  }, []);

  return (
    <div
      className={cx(
        className,
        styles[`snackbar-${type}`],
        'fui-block fui-w-full fui-rounded-md fui-p-4',
        hideDiv ? 'fui-hidden' : 'fui-block'
      )}
      data-testid='TestId__Snackbar'
    >
      {children || (
        <>
          {ableToClose ? (
            <button
              className='fui-text-black fui-absolute fui-top-3px fui-h-12px fui-w-12px fui-cursor-pointer ltr:fui-right-3px rtl:fui-left-3px'
              onClick={handleClose}
              data-testid='TestId__SnackbarCloseBtn'
            >
              <IconClose />
            </button>
          ) : null}
          {withIcon ? IconType[type] : null}
          {noteText ? (
            <p
              className={cx(styles[`paragraph-${type}`], noteTextClass)}
              dangerouslySetInnerHTML={{ __html: noteText }}
              data-testid='TestId__SnackbarParagraph'
            />
          ) : (
            ''
          )}

          {buttonTxt ? (
            <div className='fui-mt-4 fui-w-full'>
              <a
                data-testid='TestId__NavigateBtn'
                onClick={onClickNavigateBtn}
                href={buttonUrl}
                className='fui-block fui-w-full fui-border fui-border-blue-700 fui-bg-white fui-py-2 fui-text-center fui-text-sm fui-text-blue-700'
              >
                {buttonTxt}
              </a>
            </div>
          ) : null}
        </>
      )}
    </div>
  );
};
