import { errorLoggerV2 } from '@flowardco/app-shared/helpers/ddClientLogs';
import {
  API_ENDPOINTS,
  formatUrlParams,
  formatHttpResponse,
} from '@flowardco/flib-helpers';
import { addRequestHeaders, client } from './api.service';
import type { LocalAppContextProps } from '@/Context/LocalAppContext';
import { addToast, convertDateISOstart } from '@flowardco/flib-util';
import {
  formatDeliveryDatesResponse,
  formatDeliverySlotResponse,
} from '@flowardco/app-shared/helpers/utilities';

export const getDeliveryDates = async (
  appState: LocalAppContextProps,
  __TK: any,
  __T: any
) => {
  try {
    const { orderData, userToken, lang, orderToken } = appState;
    const headers = addRequestHeaders({
      operation: orderData?.operation!,
      userToken,
      lang,
    });
    const res = await client.get(
      formatUrlParams(API_ENDPOINTS.helpCenterApp.getAvailableDates, {
        orderToken,
      }),
      {
        headers,
      }
    );
    const datesList = formatHttpResponse(res);
    return formatDeliveryDatesResponse({ data: datesList || [], __TK, __T });
  } catch (error: any) {
    errorLoggerV2('DeliverySlots::getDeliveryDates', error);
    addToast(error?.response?.data?.message || 'Some error occured', 'error');
    throw new Error(error?.response?.data?.message || 'Some error occured');
  }
};
export const getDeliverySlots = async (
  appState: LocalAppContextProps,
  date: string,
  __T: any
) => {
  try {
    const { orderData, userToken, lang, orderToken } = appState;
    const headers = addRequestHeaders({
      operation: orderData?.operation!,
      userToken,
      lang,
    });
    const unixDate = convertDateISOstart(new Date(date));
    const res = await client.get(
      formatUrlParams(API_ENDPOINTS.helpCenterApp.getAvailableTimeSlots, {
        orderToken,
      }),
      {
        params: {
          date: unixDate,
          opsId: orderData?.operation?.id,
        },
        headers,
      }
    );
    const slots = formatHttpResponse(res);
    return formatDeliverySlotResponse({ data: slots || {}, __T });
  } catch (error: any) {
    errorLoggerV2('DeliverySlots::getDeliverySlots', error);
    addToast(error?.response?.data?.message || 'Some error occured', 'error');
    throw new Error(error?.response?.data?.message || 'Some error occured');
  }
};
export const saveDeliveryDateInfo = async (
  appState: LocalAppContextProps,
  formValue: any
) => {
  try {
    const { orderData, userToken, lang, orderToken } = appState;
    const headers = addRequestHeaders({
      operation: orderData?.operation!,
      userToken,
      lang,
    });
    const res = await client.post(
      formatUrlParams(API_ENDPOINTS.helpCenterApp.saveDeliveryDate, {
        orderToken,
      }),
      formValue,
      {
        headers,
      }
    );
    const formattedResponse = formatHttpResponse(res);
    if (formattedResponse?.success) {
      addToast(formattedResponse?.message);
    }
    return formattedResponse;
  } catch (error: any) {
    addToast(error.response?.data?.message, 'error');
    errorLoggerV2('Failed Saving Help center Delivery Date and Slots', error);
    throw new Error(error?.response?.data?.message || 'Some error occured');
  }
};
