import {
  getCountryList,
  getLeaveLocations,
  saveRecipientAddress,
} from '@/api/address.service';
import { PageTitle } from '@/components/PageTitle';
import { useLocalApp } from '@/hooks/useLocalApp';
import { useLocalTranslator } from '@/hooks/useLocalTranslator';
import { useQuery } from 'react-query';
import { Button } from '@flowardco/ui/Button';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useValidations } from '@flowardco/flib-hooks';
import { RecipientContactInfo } from '@/components/AddressForm/RecipientContactInfo';
import { AddressType } from '@flowardco/flib-models';
import { ContentSkeleton } from '@flowardco/app-shared/components/SkeletonLoader';
import { errorLoggerV2 } from '@flowardco/app-shared/helpers/ddClientLogs';
import { useNavigate } from 'react-router-dom';
import { addToast } from '@flowardco/flib-util';
import { SET_REFETCH_ORDER } from '@/Context/LocalAppContext';
import { SelectField } from '@flowardco/fui-input-field';

const AddUnknownAddress = () => {
  const { appState, appDispatch } = useLocalApp();
  const { __T, __TK } = useLocalTranslator();
  const { validations } = useValidations(__T);
  const navigate = useNavigate();

  const { orderData, lang } = appState;
  const validationSchema = Yup.object({
    recipientName: validations.name,
    recipientPhone: validations.phone,
  });
  const initialValues = {
    countryId: orderData?.orderAddress?.countryId || '',
    userId: orderData?.order?.userId || '',
    recipientName: orderData?.orderAddress?.recipientName || '',
    recipientPhone: orderData?.orderAddress?.recipientPhone || '',
    SaveAddress: false,
    type: AddressType.UNKNOWN,
    LeaveLocation: orderData?.order?.leaveLocation || -1,
    AddressLine: '',
  };
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    getValues,
    setValue,
    clearErrors,
    watch,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
    mode: 'all',
  });

  const { data: countryData } = useQuery(
    ['getCountryList', appState?.orderData?.order?.id],
    () => {
      return getCountryList();
    },
    {
      cacheTime: 60 * 60 * 1000,
      refetchOnWindowFocus: false,
    }
  );
  const { data: leaveLocationsList } = useQuery(
    ['getLeaveLocation', appState?.orderData?.order?.id],
    () => {
      return getLeaveLocations(appState);
    },
    {
      cacheTime: 60 * 60 * 1000,
      refetchOnWindowFocus: false,
    }
  );
  const onSubmit = async (values: any): Promise<void> => {
    try {
      const res = await saveRecipientAddress({
        data: values,
        appState,
      });
      if (res?.sucsess) {
        addToast(res?.message);
        appDispatch({ type: SET_REFETCH_ORDER, payload: Date.now() });
        navigate(`/${lang}/`);
      }
    } catch (error: any) {
      addToast(
        error?.response?.data?.message ||
          error?.message ||
          'Some error occured',
        'error'
      );
      errorLoggerV2('AddUnknownAddressPage::onSubmit', error);
    }
  };
  return (
    <div>
      <PageTitle title={__T('Update Recipient Information')} showBackButton />
      <div className='fui-px-4.5 fui-py-4'>
        {!orderData?.order?.id || !countryData || countryData?.length === 0 ? (
          <div className='fui-block'>
            <ContentSkeleton />
          </div>
        ) : (
          <form
            id='recipientDetailsForm'
            method='post'
            onSubmit={handleSubmit(onSubmit)}
            className='fui-rounded-md fui-bg-white fui-p-3 fui-shadow-sm'
          >
            <RecipientContactInfo
              translators={{ __T, __TK }}
              countries={countryData}
              register={register}
              errors={errors}
              isArabic={lang === 'ar'}
              setValue={setValue}
              clearErrors={clearErrors}
              getValues={getValues}
              watch={watch}
            />
            {orderData?.operation?.countryId === 8 ? (
              <SelectField
                label={__T('What to do if nobody is home?')}
                id='LeaveLocation'
                name='LeaveLocation'
                blankOption={__T('Select from list')}
                required={false}
                selectClassName='fui-w-full fui-mb-4'
                containerOverrideClass='fui-col-span-1'
                list={leaveLocationsList}
                onChange={(e) => setValue('LeaveLocation', e.target.value)}
                errorMessage={errors?.LeaveLocation?.message || undefined}
                value={getValues('LeaveLocation')}
              />
            ) : (
              ''
            )}
            <div className='fui-block fui-py-2'>
              <Button
                label={__T('Confirm')}
                buttonStyle='primary'
                className='fui-w-full'
                type='submit'
                size='sm'
                isProcessing={isSubmitting}
              />
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default AddUnknownAddress;
