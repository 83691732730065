import {
  setOrderSessionId,
  setUserSessionId,
  getUserSessionId,
  getOrderSessionId,
} from '@/lib/util/sessionStore';
import { APP_CONFIGS, crypto } from '@flowardco/flib-helpers';
import React, { createContext, useEffect, useReducer } from 'react';
import type { HelpcenterOrderDataModel } from '@flowardco/flib-models';
export interface LocalAppContextProps {
  isFromApp: boolean;
  isAppLoading: boolean;
  lang: string;
  orderData?: HelpcenterOrderDataModel;
  userToken: string;
  orderToken: string;
  refetchTime: number;
}

export interface AppStateActionProps {
  type: string;
  payload?: any;
}

export const defaultLocalAppContext: LocalAppContextProps = {
  isFromApp: true,
  isAppLoading: true,
  lang: 'en',
  userToken: '',
  orderToken: '',
  orderData: undefined,
  refetchTime: Date.now(),
};

export const SET_APP_LOADING = 'SET_APP_LOADING';
export const SET_USER_TOKEN = 'SET_USER_TOKEN';
export const SET_ORDER_DATA = 'SET_ORDER_DATA';
export const SET_ORDER_TOKEN = 'SET_ORDER_TOKEN';
export const SET_REFETCH_ORDER = 'SET_REFETCH_ORDER';
export const SET_IS_FROM_APP = 'SET_IS_FROM_APP';

export const LocalAppContext = createContext<any>(defaultLocalAppContext);

export const appReducer = (
  state: LocalAppContextProps,
  action: AppStateActionProps
): any => {
  switch (action.type) {
    case SET_ORDER_TOKEN:
      return {
        ...state,
        orderToken: action.payload.orderToken,
      };
    case SET_REFETCH_ORDER:
      return {
        ...state,
        refetchTime: action.payload,
      };
    case SET_USER_TOKEN:
      return {
        ...state,
        userToken: action.payload.userToken,
      };
    case SET_ORDER_DATA:
      return {
        ...state,
        isAppLoading: false,
        lang: action.payload.lang,
        orderData: action.payload.orderData,
      };
    case SET_APP_LOADING:
      return { ...state, isAppLoading: action.payload };
    case SET_IS_FROM_APP:
      return { ...state, isFromApp: action.payload };
    default:
      return state;
  }
};

export const LocalAppContextProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ ...props }) => {
  const [appState, appDispatch] = useReducer(appReducer, {
    ...defaultLocalAppContext,
  });

  const appConfigs = {
    appState,
    appDispatch,
  };
  const handleMessageListner = (event: any) => {
    if (event?.data && event?.data?.key) {
      const key = event.data.key.toLowerCase();
      let decryptedOrderToken;
      let decryptedUserToken;

      switch (key) {
        case 'ordertoken':
          decryptedOrderToken = crypto.decryptWithKey(
            event.data.orderToken,
            APP_CONFIGS.app.helpCenterSaltKey
          );
          setOrderSessionId(decryptedOrderToken);
          appDispatch({
            type: SET_ORDER_TOKEN,
            payload: {
              isAppLoading: false,
              orderToken: decryptedOrderToken,
            },
          });
          break;
        case 'usertoken':
          decryptedUserToken = crypto.decryptWithKey(
            event.data.userToken,
            APP_CONFIGS.app.helpCenterSaltKey
          );
          setUserSessionId(decryptedUserToken);

          appDispatch({
            type: SET_USER_TOKEN,
            payload: {
              isAppLoading: false,
              userToken: decryptedUserToken,
            },
          });
          break;
        case 'fromapp':
          appDispatch({
            type: SET_IS_FROM_APP,
            payload: event.data.fromapp,
          });
          break;
        default:
          break;
      }
    }
  };
  useEffect(() => {
    if (getUserSessionId() && getOrderSessionId()) {
      appDispatch({
        type: SET_USER_TOKEN,
        payload: {
          isAppLoading: false,
          userToken: getUserSessionId(),
        },
      });
      appDispatch({
        type: SET_ORDER_TOKEN,
        payload: {
          isAppLoading: false,
          orderToken: getOrderSessionId(),
        },
      });
    }
    window.addEventListener('message', handleMessageListner);
    return () => {
      window.removeEventListener('message', handleMessageListner);
    };
  }, []);
  return (
    <LocalAppContext.Provider value={appConfigs}>
      {props.children}
    </LocalAppContext.Provider>
  );
};
