import { getCustomerCareData } from '@/api/order.service';
import { useLocalApp } from '@/hooks/useLocalApp';
import { useLocalTranslator } from '@/hooks/useLocalTranslator';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import IconWhatsapp from '@flowardco/fui-icons/src/IconWhatsapp';
import IconTelephone from '@flowardco/fui-icons/src/IconTelephone';
import IconArrowRight from '@flowardco/fui-icons/src/IconArrowRight';
import { PageTitle } from '@/components/PageTitle';
import { ContentSkeleton } from '@flowardco/app-shared/components/SkeletonLoader';

type FormattedDataItem = {
  Id: number;
  Title: string;
  Value: string;
  icon: React.ElementType;
  href: string;
  target: string;
};

const CustomerCare = () => {
  const { __T } = useLocalTranslator();
  const { appState } = useLocalApp();
  const [formattedData, setFormattedData] = useState<FormattedDataItem[]>([]);
  const { data: customerCareData, isLoading } = useQuery(
    ['getCustomerCareData', appState?.orderData?.order?.id],
    () => {
      return getCustomerCareData(appState);
    }
  );

  const formatCustomerCareData = (data: any): FormattedDataItem[] => {
    return data.map((el: any) => {
      const formattedItem: FormattedDataItem = {
        ...el,
        href: '',
        target: '_blank',
      };

      switch (el?.Id) {
        case 1:
          formattedItem.icon = IconTelephone;
          formattedItem.href = `tel://${el?.Value}`;
          break;
        case 2:
          formattedItem.icon = IconWhatsapp;
          formattedItem.href = `https://wa.me/${el?.Value}`;
          break;
        default:
          break;
      }

      return formattedItem;
    });
  };

  useEffect(() => {
    if (customerCareData) {
      const formattedData = formatCustomerCareData(customerCareData);
      setFormattedData(formattedData);
    }
  }, [customerCareData]);

  return (
    <div>
      <PageTitle title={__T('Customer Care')} showBackButton />
      {isLoading ? (
        <ContentSkeleton />
      ) : (
        <div className='fui-mx-auto fui-block fui-w-full fui-max-w-md fui-p-4'>
          <ul className='fui-mb-6 fui-overflow-hidden fui-rounded-lg fui-bg-white'>
            {formattedData?.map((item, indx) => (
              <li
                className='fui-block fui-border-b fui-border-theme-neutral-200 last:fui-border-b-0'
                key={`${indx}-menu-items`}
              >
                <a
                  href={item?.href}
                  target={'_blank'}
                  rel='noreferrer'
                  className='fui-inline-flex fui-w-full fui-items-center fui-justify-start fui-bg-white fui-py-4 fui-align-middle fui-text-base fui-font-medium fui-text-theme-neutral-800 hover:fui-bg-theme-neutral-50 hover:fui-text-theme-neutral-800'
                >
                  <span className='fui-inline-flex fui-items-center fui-justify-start fui-align-middle'>
                    {item?.icon ? (
                      <item.icon className='fui-mx-2 fui-h-4 fui-w-4' />
                    ) : (
                      ''
                    )}
                  </span>
                  <span className='fui-inline-flex fui-items-center fui-justify-start fui-align-middle'>
                    {__T(item.Title)} - {item.Value}
                  </span>
                  <span className='fui-inline-flex ltr:fui-ml-auto rtl:fui-mr-auto rtl:fui-rotate-180'>
                    <IconArrowRight className='fui-mx-2 fui-h-3.5 fui-w-3.5' />
                  </span>
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default CustomerCare;
