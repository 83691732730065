import type { SelectListType } from '@flowardco/fui-input-field';
import { InputField, InputLabel } from '@flowardco/fui-input-field';
import { formatCountryList } from '@flowardco/app-shared/helpers';
import { PhoneNumber } from '@flowardco/fui-phone-number';

export interface RecipientContactInfoProps {
  translators?: any;
  countries?: any;
  register: any;
  errors: any;
  ipCountry?: string;
  isArabic: boolean;
  setValue?: any;
  clearErrors: any;
  getValues?: any;
  watch?: any;
}

export const RecipientContactInfo = ({
  countries = [],
  translators,
  register,
  setValue,
  getValues,
  errors,
  clearErrors,
  isArabic,
}: RecipientContactInfoProps) => {
  const { __T, __TK } = translators;
  const phoneCountryCodesList: SelectListType[] = formatCountryList({
    countries,
    __TK,
    isArabic,
  });

  const handleCountrySelectChange = (e: any) => {
    setValue('countryId', e.value, { shouldValidate: true, shouldDirty: true });
    clearErrors('countryId');
  };

  return (
    <div>
      <InputField
        label={__T('Recipient name')}
        labelClass='fui-text-base'
        inputFieldClass='fui-mt-1'
        id='recipientName'
        name='recipientName'
        required={true}
        register={register}
        errorMessage={errors?.recipientName?.message || undefined}
        testId='TestId__recipientName'
        value={getValues('recipientName') || ''}
      />
      <div className='fui-mt-4 '>
        <InputLabel
          label={__T('Recipient phone')}
          required={true}
          labelClass='fui-text-base'
        />
        <PhoneNumber
          wrapperClassName='fui-w-full fui-z-20 fui-mb-2'
          selectId='countryId'
          selectName='countryId'
          selectPlaceHolder={__T('Select country')}
          phoneCountryCodesList={phoneCountryCodesList}
          handleCountrySelectChange={handleCountrySelectChange}
          selectErrorMessage={errors?.countryId?.message || undefined}
          inputId='recipientPhone'
          inputName='recipientPhone'
          register={register}
          inputErrorMessage={errors?.recipientPhone?.message || undefined}
          inputTestid='TestId__recipientPhone'
          selectValue={getValues('countryId') || ''}
        />
      </div>
    </div>
  );
};
