import React, { useState, useEffect } from 'react';
import cx from 'clsx';
import { FieldErrorMessage } from './FieldErrorMessage';
import { InputLabel } from './InputLabel';
import styles from './SelectField.module.css';

//Types
export type SelectListType = { value: string | number; label: string };

export type SelectGroupList = { group: string; subgroup: SelectListType[] };

export type SelectRadiusType =
  | 'none'
  | 'sm'
  | 'default'
  | 'md'
  | 'lg'
  | 'xl'
  | '2xl'
  | '3xl';

export interface SelectFieldProps extends React.ComponentProps<'select'> {
  onChange?(event: React.ChangeEvent<HTMLSelectElement>): void;
  list?: SelectListType[];
  groupList?: SelectGroupList[];
  value: string | number;
  label?: string;
  errorMessage?: string;
  blankOption?: string;
  selectRadius?: SelectRadiusType;
  containerOverrideClass?: string;
  labelClass?: string;
  selectOverrideClass?: string;
  selectClassName?: string;
  required?: boolean;
  testId?: string;
}

export const SelectField: React.FC<SelectFieldProps> = ({
  onChange,
  id,
  name,
  list,
  groupList,
  value,
  label,
  errorMessage,
  blankOption,
  selectRadius = 'default',
  containerOverrideClass = null,
  labelClass = '',
  selectOverrideClass = null,
  selectClassName = '',
  required = false,
  testId = 'TestId__SelectField',
  ...compProps
}) => {
  const [selectedValue, setSelectedValue] = useState(value);
  useEffect(() => {
    setSelectedValue(value);
  }, [value]);
  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedValue(e.target.value);
    if (onChange) {
      onChange(e);
    }
  };
  //component css classes
  const containerClass =
    containerOverrideClass ?? cx('fui-flex fui-flex-col fui-w-full');

  const selectClass =
    selectOverrideClass ??
    cx(
      'focus:fui-border-navy-500 focus:fui-ring-0 fui-text-md fui-px-2 rtl:fui-pl-9 ltr:fui-pr-9',
      styles[`input-rounded-${selectRadius}`],
      styles['custom-rtl-select'],
      errorMessage ? 'fui-border-error-800 fui-mb-0' : 'fui-mb-2',
      selectClassName
    );

  return (
    <div className={containerClass} data-testid='TestId__SelectFieldWrapper'>
      <InputLabel
        id={`select-${id || name}`}
        labelClass={cx('fui-mb-1', labelClass)}
        label={label}
        required={required}
      />
      <select
        className={selectClass}
        name={name}
        id={`select-${id || name}`}
        value={selectedValue}
        onChange={handleChange}
        data-testid={testId}
        role={'combobox'}
        {...compProps}
      >
        {blankOption && (
          <option className='fui-text-md' value=''>
            {blankOption}
          </option>
        )}
        {/* rendering options according to given list (normal List | grouped list)*/}
        {list !== undefined
          ? list.map((item, index) => {
              return (
                <option
                  key={`${item.value}_${index}`}
                  value={item.value}
                  className='fui-text-md'
                >
                  {item.label}
                </option>
              );
            })
          : groupList &&
            groupList.map((item, index) => {
              return (
                <optgroup
                  key={`${item.group}_${index}`}
                  label={item.group}
                  className='fui-text-md'
                >
                  {item.subgroup.map((subitem, index) => {
                    return (
                      <option
                        key={`${subitem.value}_${index}`}
                        value={subitem.value}
                        className='fui-text-sm'
                      >
                        {subitem.label}
                      </option>
                    );
                  })}
                </optgroup>
              );
            })}
      </select>
      <FieldErrorMessage
        id={`select-${id || name}`}
        errorMessage={errorMessage}
        className={cx(errorMessage ? 'fui-mb-2' : '')}
        testid={testId}
      />
    </div>
  );
};
