import React, { useState } from 'react';
import cx from 'clsx';
import { InputField, InputLabel } from '@flowardco/fui-input-field';
export interface UploadBoxProps {
  id: string;
  name?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>, id: string) => void;
  className?: string;
  overrideClass?: string;
  inputClassName?: string;
  inputWrapperClassName?: string;
  header?: string;
  description?: string;
  buttonLabel?: string;
  accept?: string;
  multiple?: boolean;
  errorMessage?: any;
  isRequired?: boolean;
  descriptionClass?: string;
}

export const UploadBox: React.FC<UploadBoxProps> = ({
  id,
  name = '',
  onChange,
  className = '',
  overrideClass = '',
  inputClassName = '',
  inputWrapperClassName = '',
  header = '',
  description = '',
  buttonLabel = '',
  accept = '',
  multiple = false,
  errorMessage,
  isRequired = false,
  descriptionClass,
}) => {
  const [selectedFiles, setSelectedFiles] = useState<string>('');

  const cssClass =
    overrideClass ||
    cx(
      'fui-h-full fui-w-full fui-rounded-sm fui-border fui-border-gray-300 fui-p-4',
      className
    );

  const inputClass = cx('fui-hidden', inputClassName);

  const onInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e, id);
    }

    if (e.target.files) {
      let files = '';
      Array.from(e.target.files).map((item, index) => {
        if (index === 0) {
          files = item.name;
        } else {
          files += ', ' + item.name;
        }
      });
      setSelectedFiles(files);
    }
  };

  return (
    <div className={cssClass} data-testid='TestId__UploadBox'>
      <div className='fui-flex fui-w-full fui-flex-col fui-items-start'>
        <InputLabel
          id='UploadBox_header'
          data-testid='TestId__Header'
          label={header}
          required={isRequired}
        />
        <p data-testid='TestId__Description' className={descriptionClass}>
          {description}
        </p>
        <p className='fui-text-navy-500'>{selectedFiles}</p>
      </div>
      <div className='fui-flex fui-w-full  fui-justify-center'>
        <InputLabel
          id={`input-${id}`}
          label={buttonLabel}
          labelClass='fui-text-center fui-text-md fui-w-1/2 fui-mx-auto fui-border fui-border-navy-500 fui-mt-4 fui-bg-navy-500 fui-text-white hover:fui-bg-navy-700 fui-cursor-pointer fui-px-3 fui-py-2 fui-inline-block fui-inline-flex fui-items-center fui-justify-center'
        />
      </div>
      <InputField
        onChange={onInput}
        type='file'
        wrapperClassName={errorMessage ? inputWrapperClassName : 'fui-hidden'}
        inputFieldClass={inputClass}
        id={id}
        inputProps={{ multiple: multiple, accept: accept }}
        name={name}
        errorMessage={errorMessage}
      />
    </div>
  );
};
