import React from 'react';
import { twMerge } from 'tailwind-merge';
import { LoadingSpinner } from '@flowardco/fui-loading-spinner';
import type { ButtonProps } from './Button.types';
import {
  buttonStyles,
  buttonSizes,
  buttonTextCases,
  buttonRoundedStyles,
  buttonShadow,
} from './Button.styles';

export const Button: React.FC<ButtonProps> = ({
  children = null,
  label = null,
  className = '',
  type = 'button',
  buttonStyle = 'primary',
  size = 'default',
  rounded = 'default',
  disabled = false,
  cases = 'normal',
  shadow = 'default',
  testid = 'TestId__Button',
  isProcessing = false,
  ...buttonProps
}) => {
  return (
    <button
      type={type}
      className={twMerge(
        'fui-relative fui-inline-flex fui-items-center fui-justify-center fui-overflow-hidden fui-text-center fui-transition-colors fui-duration-200 disabled:fui-cursor-not-allowed disabled:fui-outline-none',
        buttonStyles?.[buttonStyle],
        buttonSizes?.[size],
        buttonRoundedStyles?.[rounded],
        buttonTextCases?.[cases],
        buttonShadow?.[shadow],
        className
      )}
      disabled={disabled}
      {...buttonProps}
      data-testid={testid}
    >
      {isProcessing ? (
        <div
          className={twMerge(
            'fui-absolute fui-z-10 fui-flex fui-h-full fui-w-full fui-items-center fui-justify-center fui-bg-white'
          )}
        >
          <LoadingSpinner
            iconClassName={'fui-w-20px fui-h-20px'}
            logo={false}
          />
        </div>
      ) : null}
      {children || label}
    </button>
  );
};
