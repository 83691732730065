import { PageTitle } from '@/components/PageTitle';
import { useLocalTranslator } from '@/hooks/useLocalTranslator';
import React, { useEffect, useState } from 'react';
import type { FormValues } from '@flowardco/ui/CardMessage';
import { CardMessage as CardMessageComponent } from '@flowardco/ui/CardMessage';
import { useLocalApp } from '@/hooks/useLocalApp';
import { useQuery } from 'react-query';
import { addCardMessage, getCardData } from '@/api/card.service';
import { useNavigate } from 'react-router-dom';
import { Button } from '@flowardco/ui/Button';
const CardMessage = () => {
  const { __T } = useLocalTranslator();
  const { appState } = useLocalApp();
  const [cardMsgData, setCardMsgData] = useState({
    cardTo: '',
    cardMessage: '',
    cardFrom: '',
    feelingLink: '',
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { data: cardData } = useQuery(
    ['getCardData', appState?.orderData?.order?.id],
    () => {
      return getCardData(appState);
    }
  );
  useEffect(() => {
    if (cardData?.data) {
      const { messageTo, messageFrom, messageText, feelingLink } =
        cardData.data;
      setCardMsgData({
        cardTo: messageTo,
        cardMessage: messageText,
        cardFrom: messageFrom,
        feelingLink: feelingLink,
      });
    }
  }, [cardData]);
  const onSubmitCard = async (values: FormValues) => {
    try {
      setIsLoading(true);
      const res = await addCardMessage(values, appState);
      if (res) {
        navigate(`/${appState?.lang || 'en'}`);
      }
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      <PageTitle title={__T('Edit Message Card')} showBackButton />
      <div className='fui-mt-4 fui-rounded fui-bg-white fui-p-4'>
        <CardMessageComponent
          initialValues={cardMsgData}
          className='!fui-border-0 !fui-p-0'
          __T={__T}
          onSubmitCard={onSubmitCard}
          qrCodeLink={
            cardMsgData?.feelingLink !== ''
              ? cardMsgData?.feelingLink
              : 'https://floward.com/'
          }
        />
        <Button
          label={__T('Confirm')}
          type='submit'
          form='cardMessage'
          className='fui-mt-3 fui-w-full'
          id='card_button'
          isProcessing={isLoading}
          disabled={isLoading}
        />
      </div>
    </>
  );
};

export default CardMessage;
