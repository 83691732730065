import type {
  PostalCodeItemProps,
  HelpcenterOrderDataModel,
} from '@flowardco/flib-models';

import { AppErrorBoundary } from '@flowardco/fui-app-error-boundary';
import { FieldErrorMessage, TextareaField } from '@flowardco/fui-input-field';

import { Controller } from 'react-hook-form';

import { SearchPostalCode } from '@flowardco/fui-search-postal-code';
import { useLocalTranslator } from '@/hooks/useLocalTranslator';

interface AddressWithPostalCodeProps {
  control: any;
  setValue: any;
  register: any;
  errors: any;
  getValues: any;
  orderData: HelpcenterOrderDataModel;
}

export const AddressWithPostalCode = ({
  control,
  setValue,
  register,
  errors,
  orderData,
}: AddressWithPostalCodeProps) => {
  const { __T } = useLocalTranslator();
  const postalCodeHeader = {
    lang: 1,
    opsId: orderData?.operation?.id,
    currency: 'GBP',
    OpsCountryID: orderData?.operation?.countryId,
  };
  const handlePostalCodeSelected = async (item: PostalCodeItemProps) => {
    const selectedPostal = item?.Description || '';
    setValue('postalCode', selectedPostal || '', {
      shouldValidate: true,
    });
    setValue('addressLine', item?.Text || '', {
      shouldValidate: true,
    });
  };
  return (
    <div>
      <AppErrorBoundary>
        <div>
          <Controller
            name='postalCode'
            control={control}
            render={({ field, fieldState: { error } }) => (
              <>
                <SearchPostalCode
                  label={'Postcode'}
                  headers={postalCodeHeader}
                  onPostCodeSelected={handlePostalCodeSelected}
                  name={'postalCode'}
                  validateDelivery={false}
                  onPostalCodeChange={field.onChange}
                  value={field.value}
                  hasError={!!error?.message}
                  required
                />
                <FieldErrorMessage errorMessage={error?.message || undefined} />
              </>
            )}
          />
        </div>
      </AppErrorBoundary>
      <AppErrorBoundary>
        <TextareaField
          wrapperClassName='fui-mt-4'
          label={__T('Recipient Address')}
          id='addressLine'
          name='addressLine'
          placeholder={__T('Recipient Address')}
          register={register}
          errorMessage={errors?.addressLine?.message || undefined}
          testId='TestId__addressLine'
        />
      </AppErrorBoundary>
    </div>
  );
};
