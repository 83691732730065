import * as React from 'react';
import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

const SvgIconWhatsapp = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox='0 0 14 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    ref={ref}
    {...props}
  >
    <path
      d='M11.875 2.063A6.826 6.826 0 0 0 6.969 0C3.156 0 .03 3.125.03 6.938c0 1.25.344 2.437.938 3.468L0 14l3.656-.938c1.031.532 2.156.844 3.313.844 3.843 0 7.031-3.125 7.031-6.937 0-1.875-.813-3.594-2.125-4.907ZM6.969 12.719a5.78 5.78 0 0 1-2.938-.813l-.187-.125-2.188.594.594-2.125-.156-.219c-.563-.937-.875-2-.875-3.094 0-3.156 2.593-5.75 5.781-5.75 1.531 0 2.969.594 4.063 1.688 1.093 1.094 1.75 2.531 1.75 4.094 0 3.156-2.657 5.75-5.844 5.75Zm3.187-4.313c-.187-.094-1.031-.5-1.187-.562-.156-.063-.281-.094-.406.093-.094.157-.438.563-.532.688-.125.094-.219.125-.375.031-1.031-.5-1.687-.906-2.375-2.062-.187-.313.188-.282.5-.938.063-.125.032-.218 0-.312-.031-.094-.406-.938-.531-1.282-.156-.343-.281-.312-.406-.312H4.53a.586.586 0 0 0-.468.219c-.157.187-.594.594-.594 1.437 0 .875.594 1.688.687 1.813.094.093 1.219 1.843 2.969 2.593 1.094.5 1.531.532 2.094.438.312-.031 1-.406 1.156-.813.156-.406.156-.75.094-.812-.031-.094-.156-.125-.313-.219Z'
      fill='currentColor'
    />
  </svg>
);

const ForwardRef = forwardRef(SvgIconWhatsapp);
export default ForwardRef;
