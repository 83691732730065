import React, { useState, useEffect } from 'react';
import cx from 'clsx';
import { Button } from '@flowardco/fui-button';
import { AppErrorBoundary } from '@flowardco/fui-app-error-boundary';
import { LoadingSpinner } from '@flowardco/fui-loading-spinner';
import type { Coords } from 'google-map-react';
import type { GoogleMapProps } from '@flowardco/fui-google-map';
import { GoogleMap } from '@flowardco/fui-google-map';
import useAddressMapApi from './useAddressMapApi';

export interface AddressAreaMapProps extends GoogleMapProps {
  onConfirm?: (address: any) => void;
  lang: string;
  countryId: number;
  opsId: number;
  currency: string;
  countryCode: string;
  deliverToLabel?: string;
  confirmButtonLabel?: string;
  __TK: (key?: string, params?: any) => string;
  __T: (key?: string, params?: any) => string;
  handleNonAvailableArea?: () => void;
  validateDropPin?: boolean;
  handleMapSelection?: (data: { latLang?: Coords; address?: any }) => void;
  enableGeoLocation?: boolean;
  orderId?: string | number;
}

export const AddressAreaMap: React.FC<AddressAreaMapProps> = ({
  apiKey,
  countryCode,
  center,
  lang = 'en',
  countryId = 1,
  opsId = 1,
  currency = 'KWD',
  deliverToLabel = 'Deliver to',
  confirmButtonLabel = 'Confirm',
  onConfirm,
  defaultToMyLocation = false,
  __TK,
  __T,
  handleNonAvailableArea,
  validateDropPin = true,
  handleMapSelection,
  enableGeoLocation = false,
  orderId = '',
}) => {
  const [addressError, setAddressError] = useState<boolean>(false);
  const [addressLine, setAddressLine] = useState<string>('');
  const [address, setAddress] = useState<any>({});
  const [mapSelectedData, setMapSelectedData] = useState<{
    latLang?: Coords;
    address?: any;
  }>({ latLang: undefined, address: undefined });
  const { isLoading, getAddressLocation, getAddressGeoLocation } =
    useAddressMapApi({
      lang: lang === 'ar' ? 0 : 1,
      countryId,
      opsId,
      currency,
    });
  const handleLatLangChange = async (latLang: Coords, address?: string) => {
    setMapSelectedData({ latLang, address });
    if (!validateDropPin) {
      return;
    }
    try {
      if (enableGeoLocation) {
        const data = await getAddressGeoLocation(
          latLang,
          address || '',
          orderId
        );
        if (data.isError || !data.isGeofenced) {
          if (handleNonAvailableArea) {
            handleNonAvailableArea();
          }
          setAddressLine(
            data?.message || __T("Sorry, we don't deliver to this area")
          );
          setAddress(null);
          setAddressError(true);
          return;
        }
        setAddress(data);
        setAddressLine(data?.addressLineOne || '');
        setAddressError(false);
      } else {
        const data = await getAddressLocation(latLang, address || '');
        if (data.isError) {
          if (handleNonAvailableArea) {
            handleNonAvailableArea();
          }
          setAddressLine(data?.message);
          setAddress(null);
          setAddressError(true);
          return;
        }
        setAddress(data);
        setAddressLine(data?.addressLineOne || '');
        setAddressError(false);
      }
    } catch (err) {
      // console.log(err);
    }
  };
  useEffect(() => {
    if (center?.lat && center?.lng) {
      handleLatLangChange(center);
    }
  }, [center]);

  const handleConfirmAddress = () => {
    if (onConfirm) {
      onConfirm(address);
    }
    if (handleMapSelection) {
      handleMapSelection(mapSelectedData);
    }
  };
  return (
    <div className='fui-block fui-w-full'>
      <div className='fui-relative fui-h-[500px]'>
        <AppErrorBoundary>
          <GoogleMap
            data-testid='TestId__AddressFormMap'
            showMarker={true}
            showPlacesAutoComplete={true}
            apiKey={apiKey}
            countryCode={countryCode}
            onLatLngChanged={handleLatLangChange}
            center={center}
            libraries={['places']}
            defaultToMyLocation={defaultToMyLocation}
          />
        </AppErrorBoundary>
        <div
          className={cx(
            'fui-absolute fui-bottom-4 fui-left-0 fui-right-0 fui-mx-auto fui-block fui-w-[95%] fui-rounded-md fui-bg-white fui-p-3 fui-shadow-down'
          )}
        >
          {validateDropPin ? (
            <div className='fui-mb-4 fui-block fui-break-words'>
              {__T(deliverToLabel)}
              {' : '}
              {__TK(address?.area?.name || '', address?.area?.nameAR || '')}
              {' - '}
              {addressLine}
            </div>
          ) : (
            ''
          )}
          <div className='fui-relative fui-block'>
            {isLoading ? (
              <LoadingSpinner
                logo={false}
                className='fui-absolute fui-flex fui-h-full fui-w-full fui-items-center fui-justify-end fui-px-4'
                iconClassName='fui-text-salmon-900 fui-w-6 fui-h-6'
              />
            ) : (
              ''
            )}
            <Button
              disabled={addressError}
              label={__T(confirmButtonLabel)}
              onClick={handleConfirmAddress}
              className={cx('!fui-inline fui-w-full fui-text-center')}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
