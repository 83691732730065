import * as React from 'react';
import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

const SvgIconMapMarkerFilled = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox='0 0 13 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    ref={ref}
    {...props}
  >
    <path
      d='M0 7.15C0 3.474 2.906.5 6.5.5 10.09.5 13 3.473 13 7.15c0 3.668-6.5 12.35-6.5 12.35S0 10.818 0 7.15Z'
      fill='currentColor'
    />
  </svg>
);

const ForwardRef = forwardRef(SvgIconMapMarkerFilled);
export default ForwardRef;
