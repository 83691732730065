import { useFeatureValue } from '@growthbook/growthbook-react';

export interface RecipientExperiementFlagsHook {
  getGeoLocationEnabledOps: () => Array<{ opsId: number }>;
}

export const useMapMasterExperimentFlags =
  (): RecipientExperiementFlagsHook => {
    const getGeoLocationEnabledOps = () => {
      const newAddressSlotFlowEnabled = useFeatureValue(
        'enable-web-checkout-map-master',
        []
      );
      return newAddressSlotFlowEnabled;
    };
    return {
      getGeoLocationEnabledOps,
    };
  };
