import * as React from 'react';
import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

const SvgIconTelephone = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox='0 0 16 17'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    ref={ref}
    {...props}
  >
    <path
      d='m15.125 10.313-3.188-1.344a1.493 1.493 0 0 0-.562-.125c-.438 0-.844.219-1.094.531l-1.031 1.25A9.637 9.637 0 0 1 5.375 6.75l1.25-1.031c.313-.25.531-.657.531-1.094 0-.219-.062-.406-.125-.563L5.688.876A1.49 1.49 0 0 0 4.343 0c-.094 0-.188.031-.313.063L1.094.719A1.444 1.444 0 0 0 0 2.125C0 9.813 6.219 16.031 13.875 16c.688 0 1.25-.469 1.406-1.094l.656-2.937c.032-.125.063-.219.063-.313a1.49 1.49 0 0 0-.875-1.344Zm-1.313 4.218C7.032 14.5 1.533 8.97 1.5 2.187l2.813-.656 1.312 3.063L3.5 6.344C4.969 9.437 6.563 11.03 9.656 12.5l1.75-2.125 3.063 1.313-.656 2.843Z'
      fill='currentColor'
    />
  </svg>
);

const ForwardRef = forwardRef(SvgIconTelephone);
export default ForwardRef;
