import { SESSION_KEYS } from '@/lib/helpers/constants';
import { sessionStore } from '@flowardco/flib-helpers';

export const setUserSessionId = (sessionId: string): void => {
  sessionStore.set(SESSION_KEYS.SESSION_ID, sessionId, false);
};

export const getUserSessionId = (): string | null => {
  return sessionStore.get(SESSION_KEYS.SESSION_ID, false) || null;
};

export const setOrderSessionId = (sessionId: string): void => {
  sessionStore.set(SESSION_KEYS.ORDER_TOKEN_ID, sessionId, false);
};
export const getOrderSessionId = (): string | null => {
  return sessionStore.get(SESSION_KEYS.ORDER_TOKEN_ID, false) || null;
};
