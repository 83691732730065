import React, { useState, useEffect } from 'react';
import cx from 'clsx';
import GoogleMapReact from 'google-map-react';
import type { Coords, Size, Bounds, MapOptions } from 'google-map-react';
import { AutoCompletePlaces } from './AutoCompletePlaces';
import { AppErrorBoundary } from '@flowardco/fui-app-error-boundary';

export interface GoogleMapProps {
  apiKey: string;
  children?: React.ReactNode;
  zoom?: number;
  center?: Coords;
  onAPILoaded?(maps: { map: any; maps: any; ref: Element | null }): void;
  mapOptions?: MapOptions;
  libraries?: string[] | string | undefined;
  wrapperClassName?: string;
  showMarker?: boolean;
  showPlacesAutoComplete?: boolean;
  onLatLngChanged?(latLng: Coords, address?: any): any;
  markerImage?: string;
  countryCode: string;
  searchInputName?: string;
  searchInputPlaceholder?: string;
  defaultToMyLocation?: boolean;
}

export const GoogleMap: React.FC<GoogleMapProps> = ({
  apiKey,
  zoom = 16,
  center,
  onAPILoaded,
  libraries = ['places'],
  wrapperClassName,
  showMarker = true,
  showPlacesAutoComplete = true,
  searchInputName = 'SearchAutoCompleteInputName',
  searchInputPlaceholder = 'Search',
  onLatLngChanged,
  markerImage = 'https://floward.imgix.net/web/pin-mark.svg?width=50',
  mapOptions = {
    disableDefaultUI: true,
    zoomControl: false,
    clickableIcons: false,
    mapTypeId: 'terrain',
  },
  countryCode,
  defaultToMyLocation = false,
}) => {
  let marker: any;

  const autoCompleteOptions = {
    componentRestrictions: { country: countryCode },
    fields: ['name', 'geometry.location', 'geometry.viewport', 'place_id'],
  };

  const [isMarkerDragging, setIsMarkerDragging] = useState(false);
  const [googleMap, setGoogleMap] = useState<any>();
  const [googleMapMarker, setGoogleMapMarker] = useState<any>();

  const setMarkerToCenter = (
    latLng: Coords,
    placeName?: string,
    callOnChange: boolean = false
  ) => {
    if (marker && marker.setPosition && !isMarkerDragging) {
      marker.setPosition(latLng);
    }
    if (callOnChange && onLatLngChanged) {
      onLatLngChanged(latLng, placeName);
    }
  };

  const addAutoCompleteInput = (gMaps: any, gMap: any) => {
    const input = document.getElementById(searchInputName) as HTMLInputElement;
    const autocomplete = new gMaps.places.Autocomplete(
      input,
      autoCompleteOptions
    );
    autocomplete.bindTo('bounds', gMap);
    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();
      if (!place.geometry || !place.geometry.location) {
        return;
      }
      // If the place has a geometry, then present it on a map.
      gMap.setCenter(place.geometry.location);
      const latLng = place.geometry.location.toJSON();
      setMarkerToCenter(latLng, place.name, true);
    });
  };

  const addMarker = (latLng: Coords, gMap: any, gMaps: any) => {
    if (latLng) {
      marker = new gMaps.Marker({
        position: latLng,
        map: gMap,
        draggable: true,
        icon: markerImage,
      });
      marker.setMap(gMap);
      setGoogleMapMarker(marker);
    }
    if (gMaps) {
      gMaps.event.addListener(marker, 'dragstart', () => {
        setIsMarkerDragging(true);
      });
      gMaps.event.addListener(marker, 'dragend', () => {
        setIsMarkerDragging(false);
        setMarkerToCenter(
          { lat: marker.getPosition().lat(), lng: marker.getPosition().lng() },
          '',
          true
        );
      });
    }
  };

  const handleMyLocationClick = () => {
    try {
      navigator.geolocation.getCurrentPosition((myLocation) => {
        const { latitude: lat, longitude: lng } = myLocation.coords;
        const latLng = { lat, lng };
        if (googleMap && googleMap.panTo) {
          googleMap.setCenter(latLng);
        }
        setMarkerToCenter(latLng, '', true);
        if (googleMapMarker && googleMapMarker.setPosition) {
          googleMapMarker.setPosition(latLng);
        }
      });
    } catch (err) {
      // log the error
    }
  };

  const handleBoundsChange = (changeValue: {
    center: Coords;
    zoom: number;
    bounds: Bounds;
    marginBounds: Bounds;
    size: Size;
  }) => {
    if (googleMapMarker && googleMapMarker.setPosition) {
      googleMapMarker.setPosition(changeValue.center);
    }
  };

  const handleApiLoaded = ({
    map,
    maps,
    ref,
  }: {
    map: any;
    maps: any;
    ref: Element | null;
  }) => {
    if (onAPILoaded) {
      onAPILoaded({ map, maps, ref });
    }
    if (showMarker && center && center.lat) {
      addMarker(center, map, maps);
    }
    if (showPlacesAutoComplete) {
      addAutoCompleteInput(maps, map);
    }

    maps.event.addListener(map, 'click', (event: any) => {
      setMarkerToCenter(
        { lat: event.latLng.lat(), lng: event.latLng.lng() },

        '',
        true
      );
    });
    maps.event.addListener(map, 'drag', () => {
      setMarkerToCenter(
        { lat: map.getCenter().lat(), lng: map.getCenter().lng() },
        '',
        false
      );
    });
    maps.event.addListener(map, 'dragend', () => {
      setMarkerToCenter(
        { lat: map.getCenter().lat(), lng: map.getCenter().lng() },
        '',
        true
      );
    });
    map.addListener('zoom_changed', () => {
      setMarkerToCenter(
        { lat: map.getCenter().lat(), lng: map.getCenter().lng() },
        '',
        true
      );
    });
    setGoogleMap(map);
  };

  useEffect(() => {
    if (googleMap && defaultToMyLocation) {
      handleMyLocationClick();
    }
  }, [googleMap, defaultToMyLocation]);

  return (
    <div
      className={cx(
        'fui-relative fui-flex fui-h-full fui-w-full fui-flex-1',
        wrapperClassName
      )}
      data-testid='TestId__GoogleMap'
    >
      <AppErrorBoundary>
        {showPlacesAutoComplete ? (
          <AutoCompletePlaces
            searchInputName={searchInputName}
            searchInputPlaceholder={searchInputPlaceholder}
            onMyLocationClick={handleMyLocationClick}
          />
        ) : (
          ''
        )}
      </AppErrorBoundary>
      <AppErrorBoundary>
        <GoogleMapReact
          bootstrapURLKeys={{ key: apiKey, libraries }}
          center={center}
          zoom={zoom}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={handleApiLoaded}
          onChange={handleBoundsChange}
          options={mapOptions}
        />
      </AppErrorBoundary>
    </div>
  );
};
