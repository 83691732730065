import {
  getCountryList,
  getSavedAddressList,
  saveRecipientAddress,
} from '@/api/address.service';
import { PageTitle } from '@/components/PageTitle';
import { useLocalApp } from '@/hooks/useLocalApp';
import { useLocalTranslator } from '@/hooks/useLocalTranslator';
import { useQuery } from 'react-query';
import { AddressCard } from '@/components/AddressCard';
import { ContentSkeleton } from '@flowardco/app-shared/components/SkeletonLoader';
import { useState } from 'react';
import { Button, ButtonLink } from '@flowardco/ui/Button';
import { Link, useNavigate } from 'react-router-dom';
import IconPlus from '@flowardco/fui-icons/src/IconPlus';
import IconArrowRight from '@flowardco/fui-icons/src/IconArrowRight';
import { addToast } from '@flowardco/flib-util';
import { SET_REFETCH_ORDER } from '@/Context/LocalAppContext';
import { errorLoggerV2 } from '@flowardco/app-shared/helpers/ddClientLogs';
import { AddressType } from '@flowardco/flib-models';

const Addresspage = () => {
  const { __T } = useLocalTranslator();
  const { appState, appDispatch } = useLocalApp();
  const [selectedSavedAddress, setSelectedSavedAddress] = useState<any>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const navigate = useNavigate();
  const onAddressSelect = (item: any) => {
    setSelectedSavedAddress(item);
  };
  const { orderData, lang } = appState;
  const { data: countryData } = useQuery(
    ['getCountryList', appState?.orderData?.order?.id],
    () => {
      return getCountryList();
    },
    {
      cacheTime: 60 * 60 * 1000,
      refetchOnWindowFocus: false,
    }
  );
  const { isLoading, data: addresses } = useQuery(
    ['getSavedAddressList', appState?.orderData?.order?.userId],
    () => {
      return getSavedAddressList(appState);
    },
    {
      cacheTime: 0.5 * 60 * 1000,
      refetchOnWindowFocus: false,
    }
  );
  const handleSavedAddressSubmit = async (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();
    if (selectedSavedAddress?.id) {
      setIsSubmitting(true);
      try {
        const values = {
          AreaId: selectedSavedAddress?.areaID,
          AddressId: selectedSavedAddress?.id,
          recipientName: selectedSavedAddress?.recipientName,
          recipientPhone: selectedSavedAddress?.recipientPhone,
          lat: selectedSavedAddress?.lat,
          lng: selectedSavedAddress?.lng,
          AddressLine: selectedSavedAddress?.addressLineOne,
          AddressLinetwo: selectedSavedAddress?.addressLineTwo,
          PostalCode: selectedSavedAddress?.postalCode,
          SaveAddress: false,
          type: AddressType.HOME,
          LeaveLocation: orderData?.order?.leaveLocation || -1,
          countryID: selectedSavedAddress?.countryID,
          userId: orderData?.order?.userId,
        };
        const res = await saveRecipientAddress({
          data: values,
          appState,
        });
        if (res?.sucsess) {
          addToast(res?.message);
          appDispatch({ type: SET_REFETCH_ORDER, payload: Date.now() });
          navigate(`/${lang}/`);
        }
      } catch (error: any) {
        addToast(
          error?.response?.data?.message ||
            error?.message ||
            'Some error occured',
          'error'
        );
        errorLoggerV2('Addresspage::handleSavedAddressSubmit', error);
      } finally {
        setIsSubmitting(false);
      }
    }
  };
  return (
    <div>
      <PageTitle title={__T('Change Delivery Address')} showBackButton />
      <div className='fui-px-4.5 '>
        {orderData?.orderAddress?.addressLineOne ? (
          <div className='fui-block fui-py-2 fui-text-theme-neutral-800'>
            <h3 className='fui-block fui-text-md fui-font-semibold'>
              {__T('Previous Delivery Address')}:
            </h3>
            <p className='fui-py-1 fui-text-md'>
              {orderData?.orderAddress?.addressLineOne}
            </p>
          </div>
        ) : (
          ''
        )}

        {isLoading ? (
          <div className='fui-block'>
            <ContentSkeleton />
          </div>
        ) : (
          <div className='fui-block'>
            {addresses?.length > 0 ? (
              <>
                <h3 className='fui-block fui-px-0.5 fui-py-2 fui-text-lg fui-font-semibold fui-text-theme-neutral-800'>
                  {__T('Select from previous addresses')}
                </h3>
                {addresses?.map((item: any) => {
                  const recipientPhoneCountryCode = countryData?.find(
                    (country: any) => country.Id === item?.countryID
                  )?.Code;
                  const recipientPhoneNumber = item?.recipientPhone;
                  const recipientPhone = `${
                    recipientPhoneCountryCode
                      ? `+${recipientPhoneCountryCode}-`
                      : ''
                  }${recipientPhoneNumber}`;
                  return (
                    <AddressCard
                      key={item.id}
                      {...item}
                      nameLabel={__T('Name')}
                      phoneLabel={__T('Phone')}
                      recipientPhone={recipientPhone}
                      selected={selectedSavedAddress?.id === item?.id}
                      onAddressSelect={() => onAddressSelect(item)}
                    />
                  );
                })}
              </>
            ) : (
              ''
            )}
          </div>
        )}
        <div className='fui-block fui-py-2'>
          <ButtonLink
            buttonStyle='secondary'
            size='sm'
            className='fui-w-full'
            renderAs={Link}
            renderElemProps={{ to: `/${lang}/delivery-address/add-new` }}
          >
            <span className='fui-inline-flex fui-items-center fui-justify-center fui-px-0.5'>
              <IconPlus className='fui-w-5' strokeWidth={3} />
            </span>
            <span className='fui-inline-flex fui-items-center fui-justify-center fui-px-0.5'>
              {__T('Add New Address')}
            </span>
          </ButtonLink>
        </div>
        <div className='fui-my-3 fui-block fui-overflow-hidden fui-rounded-lg fui-border fui-border-theme-neutral-200 fui-bg-white fui-shadow-sm'>
          <div className='fui-block'>
            <Link
              to={`/${lang}/delivery-address/update-unknown`}
              className='fui-inline-flex fui-w-full fui-items-center fui-justify-between fui-bg-white fui-py-4 fui-align-middle fui-text-base fui-font-medium fui-text-theme-neutral-800 hover:fui-bg-theme-neutral-50 hover:fui-text-theme-neutral-800'
            >
              <p className='fui-inline-flex fui-w-full fui-flex-col fui-items-start fui-justify-start fui-px-4'>
                <span className='fui-block fui-text-base fui-font-semibold'>
                  {__T("Don't know the address?")}
                </span>
                <span className='fui-block fui-text-md'>
                  {__T('We will contact the recipient to get the address')}
                </span>
              </p>
              <span className='fui-inline-flex ltr:fui-ml-auto rtl:fui-mr-auto rtl:fui-rotate-180'>
                <IconArrowRight className='fui-mx-2 fui-h-3.5 fui-w-3.5' />
              </span>
            </Link>
          </div>
        </div>
        <div className='fui-block fui-py-2'>
          <Button
            label={__T('Confirm')}
            buttonStyle='primary'
            className='fui-w-full'
            type='button'
            size='sm'
            isProcessing={isSubmitting}
            disabled={!selectedSavedAddress?.id || isSubmitting}
            onClick={handleSavedAddressSubmit}
          />
        </div>
      </div>
    </div>
  );
};

export default Addresspage;
