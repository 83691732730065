import { Outlet, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useLocalApp } from '@/hooks/useLocalApp';
import { useEffect, Suspense, Fragment } from 'react';
import { FullPageLoader } from '@flowardco/app-shared/components/AppLoader';

const LayoutPage = () => {
  const { lang = 'en' } = useParams();
  const language = lang.toLowerCase() === 'ar' ? 'ar' : 'en';
  const dir = language === 'ar' ? 'rtl' : 'ltr';
  const {
    getOrderData,
    appState: { isAppLoading, userToken, orderToken, refetchTime },
  } = useLocalApp();
  useEffect(() => {
    if (orderToken && userToken && refetchTime) {
      getOrderData();
    }
  }, [orderToken, userToken, refetchTime]);
  if (isAppLoading) {
    return (
      <Fragment>
        <FullPageLoader />
      </Fragment>
    );
  }
  return (
    <>
      <Helmet>
        <html lang={language || 'en'} dir={dir} />
      </Helmet>
      <Suspense fallback={<FullPageLoader />}>
        <div className='fui-mx-auto fui-flex fui-max-w-[500px] fui-flex-col fui-justify-start fui-bg-gray-60'>
          <main className='fui-flex fui-flex-1 fui-flex-col fui-p-4'>
            <Outlet />
          </main>
        </div>
      </Suspense>
    </>
  );
};

export default LayoutPage;
