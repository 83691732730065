import type { InputHTMLAttributes } from 'react';
import React from 'react';
import cx from 'clsx';
import { InputLabel } from './InputLabel';
import { FieldErrorMessage } from './FieldErrorMessage';
export interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  type?: 'checkbox' | 'radio';
  inputClasses?: string;
  label?: string;
  labelClass?: string;
  errorMessage?: string;
  labelParentClass?: string;
  register?: any;
  count?: number;
  isFamilySelected?: boolean;
  labelTextClasses?: string;
  labelWithIcon?: React.ReactNode;
}

export const Checkbox: React.FC<CheckboxProps> = ({
  onChange = () => null,
  value,
  checked = false,
  type = 'checkbox',
  label,
  inputClasses,
  required,
  name,
  id,
  disabled,
  labelClass,
  errorMessage,
  defaultChecked = false,
  labelParentClass,
  register,
  count = 0,
  isFamilySelected,
  labelTextClasses,
  labelWithIcon,
}) => (
  <label
    className={cx(
      `fui-flex fui-cursor-pointer fui-items-center`,
      labelParentClass
    )}
    data-testid='TestId__Checkbox'
  >
    {register ? (
      <input
        className={cx(
          `fui-mx-1 fui-cursor-pointer fui-rounded fui-text-navy-500 focus:fui-ring-0`,
          inputClasses
        )}
        type={type}
        {...register}
        onChange={onChange}
        name={name}
        defaultChecked={defaultChecked}
        id={`checkbox-${id || name}`}
        disabled={disabled}
        data-testid='TestId__CheckboxInputField'
        value={value}
      />
    ) : (
      <input
        className={cx(
          `fui-mx-1 fui-cursor-pointer fui-text-navy-500 focus:fui-ring-0 ${
            type === 'checkbox' ? 'fui-rounded' : 'fui-rounded-full'
          }`,
          inputClasses
        )}
        type={type}
        onChange={onChange}
        checked={checked || defaultChecked}
        name={name}
        id={`checkbox-${id || name}`}
        value={value}
        disabled={disabled}
        data-testid='TestId__CheckboxInputField'
      />
    )}
    <InputLabel
      id={`checkbox-${id || name}`}
      label={label || labelWithIcon}
      required={required}
      count={count}
      labelClass={cx('fui-cursor-pointer', labelClass)}
      isFamilySelected={isFamilySelected}
      labelTextClasses={labelTextClasses}
    />
    {errorMessage && (
      <FieldErrorMessage
        id={`checkbox-${id || name}`}
        errorMessage={errorMessage}
      />
    )}
  </label>
);
