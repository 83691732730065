import React, { useEffect, useState } from 'react';
import cx from 'clsx';
import { FieldErrorMessage } from './FieldErrorMessage';
import { InputLabel } from './InputLabel';

export interface TextareaFieldProps extends React.ComponentProps<'textarea'> {
  errorMessage?: string;
  wrapperClass?: string;
  labelClass?: string;
  textAreaClassName?: string;
  label?: string;
  required?: boolean;
  name: string;
  rowsNumber?: number;
  colsNumber?: number;
  maxLength?: number;
  wrapperClassName?: string;
  inputProps?: React.InputHTMLAttributes<HTMLTextAreaElement>;
  testId?: string;
  otherProps?: any;
  register?: any;
}

export const TextareaField: React.FC<TextareaFieldProps> = ({
  wrapperClassName = '',
  labelClass = '',
  textAreaClassName = '',
  label,
  required = false,
  id,
  rowsNumber = 4,
  colsNumber = 5,
  maxLength = 9999999999,
  onChange,
  onBlur,
  name,
  disabled = false,
  errorMessage,
  placeholder,
  value = '',
  testId = 'TestId__TextareaField',
  inputProps = {},
  register,
  otherProps = {},
}) => {
  const [fieldValue, setFieldValue] = useState(value);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFieldValue(e.target.value);
    if (onChange) {
      onChange(e);
    }
  };
  useEffect(() => {
    setFieldValue(value);
  }, [value]);
  return (
    <div
      className={cx('fui-block fui-w-full', wrapperClassName)}
      data-testid='TestId__TextareaFieldWrapper'
    >
      <InputLabel
        id={`textarea-${id || name}`}
        labelClass={cx('fui-mb-1', labelClass)}
        label={label}
        required={required}
      />

      {register ? (
        <textarea
          data-testid={testId}
          id={`textarea-${id || name}`}
          rows={rowsNumber}
          cols={colsNumber}
          maxLength={maxLength}
          className={cx(
            textAreaClassName,
            'fui-w-full fui-rounded-sm fui-border fui-shadow-none fui-outline-none fui-ring-0 focus:fui-border-navy-500 focus:fui-shadow-none focus:fui-outline-none focus:fui-ring-0'
          )}
          placeholder={placeholder}
          disabled={disabled}
          name={name}
          {...register(id)}
          {...inputProps}
          {...otherProps}
          onBlur={onBlur}
        />
      ) : (
        <textarea
          data-testid={testId}
          id={`textarea-${id || name}`}
          rows={rowsNumber}
          cols={colsNumber}
          maxLength={maxLength}
          className={cx(
            textAreaClassName,
            'fui-w-full fui-rounded-sm fui-border fui-shadow-none fui-outline-none fui-ring-0 focus:fui-border-navy-500 focus:fui-shadow-none focus:fui-outline-none focus:fui-ring-0'
          )}
          placeholder={placeholder}
          disabled={disabled}
          onChange={handleChange}
          name={name}
          value={fieldValue}
          {...inputProps}
          {...otherProps}
          onBlur={onBlur}
        />
      )}

      <FieldErrorMessage
        id={`textarea-${id || name}`}
        errorMessage={errorMessage}
      />
    </div>
  );
};
