import * as React from 'react';
import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

const SvgIconLoadingSpinner = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    fill='none'
    viewBox='0 0 32 32'
    xmlns='http://www.w3.org/2000/svg'
    ref={ref}
    {...props}
  >
    <mask id='path-1-inside-1_741_1861' fill='white'>
      <path d='M30.4 16C31.2837 16 32.0083 15.2819 31.9201 14.4026C31.6592 11.8025 30.7643 9.29712 29.3035 7.11088C27.5454 4.47969 25.0466 2.42893 22.1229 1.21793C19.1993 0.00692534 15.9823 -0.309928 12.8786 0.307436C9.77486 0.924799 6.92393 2.44865 4.68629 4.68629C2.44865 6.92393 0.924799 9.77486 0.307435 12.8786C-0.309928 15.9823 0.00692538 19.1993 1.21793 22.1229C2.42893 25.0466 4.47969 27.5454 7.11088 29.3035C9.29712 30.7643 11.8025 31.6592 14.4026 31.9201C15.2819 32.0083 16 31.2837 16 30.4C16 29.5163 15.2809 28.8103 14.4041 28.7001C12.438 28.4531 10.5484 27.7518 8.8887 26.6428C6.78375 25.2363 5.14314 23.2372 4.17434 20.8983C3.20554 18.5595 2.95206 15.9858 3.44595 13.5028C3.93984 11.0199 5.15892 8.73915 6.94903 6.94903C8.73915 5.15892 11.0199 3.93984 13.5028 3.44595C15.9858 2.95206 18.5595 3.20554 20.8983 4.17434C23.2372 5.14314 25.2363 6.78375 26.6428 8.8887C27.7518 10.5484 28.4531 12.438 28.7001 14.4041C28.8103 15.2809 29.5163 16 30.4 16Z' />
    </mask>
    <path
      d='M30.4 16C31.2837 16 32.0083 15.2819 31.9201 14.4026C31.6592 11.8025 30.7643 9.29712 29.3035 7.11088C27.5454 4.47969 25.0466 2.42893 22.1229 1.21793C19.1993 0.00692534 15.9823 -0.309928 12.8786 0.307436C9.77486 0.924799 6.92393 2.44865 4.68629 4.68629C2.44865 6.92393 0.924799 9.77486 0.307435 12.8786C-0.309928 15.9823 0.00692538 19.1993 1.21793 22.1229C2.42893 25.0466 4.47969 27.5454 7.11088 29.3035C9.29712 30.7643 11.8025 31.6592 14.4026 31.9201C15.2819 32.0083 16 31.2837 16 30.4C16 29.5163 15.2809 28.8103 14.4041 28.7001C12.438 28.4531 10.5484 27.7518 8.8887 26.6428C6.78375 25.2363 5.14314 23.2372 4.17434 20.8983C3.20554 18.5595 2.95206 15.9858 3.44595 13.5028C3.93984 11.0199 5.15892 8.73915 6.94903 6.94903C8.73915 5.15892 11.0199 3.93984 13.5028 3.44595C15.9858 2.95206 18.5595 3.20554 20.8983 4.17434C23.2372 5.14314 25.2363 6.78375 26.6428 8.8887C27.7518 10.5484 28.4531 12.438 28.7001 14.4041C28.8103 15.2809 29.5163 16 30.4 16Z'
      stroke='currentColor'
      strokeWidth={8}
      mask='url(#path-1-inside-1_741_1861)'
    />
  </svg>
);

const ForwardRef = forwardRef(SvgIconLoadingSpinner);
export default ForwardRef;
