import React from 'react';
import IconChevronRight from '@flowardco/fui-icons/src/IconChevronRight';
import type { PostalCodeItemProps } from '@flowardco/flib-models';

export const PostalCodeList: React.FC<{
  postalCodes: PostalCodeItemProps[];
  onSelect: (item: PostalCodeItemProps) => Promise<any>;
}> = ({ postalCodes, onSelect }) => {
  return (
    <div className='fui-absolute fui-z-50 fui-w-full	fui-overflow-auto fui-rounded fui-border fui-border-gray-400 fui-bg-white fui-shadow-md'>
      <ul
        data-testid='TestId__SearchPostalCodeList'
        className='fui-m-0 fui-max-h-52 fui-list-none fui-p-0'
      >
        {postalCodes.map((item, idx) => (
          <li
            data-testid='TestId__SearchPostalCodeItem'
            onClick={() => onSelect(item)}
            key={`${item.Id}_${idx}`}
            className='fui-bg-grey-50 delay-200 fui-m-0 fui-flex fui-cursor-pointer fui-justify-between fui-rounded-none fui-px-3.5 fui-py-2 fui-text-sm fui-text-black-600	fui-transition hover:fui-bg-gray-50 md:fui-text-base'
          >
            {item.Type === 'Address' ? item.Text : item.Description}

            {item.Type !== 'Address' && (
              <IconChevronRight className='fui-h-4 fui-w-4 fui-text-gray-400' />
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};
