import React from 'react';
import { twMerge } from 'tailwind-merge';
import type { ButtonLinkProps } from './Button.types';
import {
  buttonStyles,
  buttonSizes,
  buttonTextCases,
  buttonRoundedStyles,
  buttonShadow,
} from './Button.styles';

export const ButtonLink: React.FC<ButtonLinkProps> = ({
  children = null,
  label = null,
  className = '',
  buttonStyle = 'primary',
  size = 'default',
  rounded = 'default',
  cases = 'normal',
  shadow = 'default',
  testid = 'TestId__ButtonLink',
  renderAs = 'a',
  renderElemProps = {},
  ...linkProps
}) => {
  const Comp = renderAs ? renderAs : 'a';
  return (
    <Comp
      className={twMerge(
        'fui-relative fui-inline-flex fui-items-center fui-justify-center fui-overflow-hidden fui-text-center fui-transition-colors fui-duration-200 disabled:fui-cursor-not-allowed disabled:fui-outline-none',
        buttonStyles?.[buttonStyle],
        buttonSizes?.[size],
        buttonRoundedStyles?.[rounded],
        buttonTextCases?.[cases],
        buttonShadow?.[shadow],
        className
      )}
      {...linkProps}
      {...renderElemProps}
      data-testid={testid}
    >
      {children || label}
    </Comp>
  );
};
