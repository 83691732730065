import TTLCache from '@isaacs/ttlcache';

export const ssrCache = new TTLCache({
  max: 1000,
  ttl: 1000 * 60 * 15,
});

export const ssrHomeCache = new TTLCache({
  max: 500,
  ttl: 1000 * 60 * 15,
});
