import { twMerge } from 'tailwind-merge';
import IconMapMarker from '@flowardco/fui-icons/src/IconMapMarker';
import IconTickRounded from '@flowardco/fui-icons/src/IconTickRounded';

export const AddressCard = ({
  areaID,
  className = '',
  recipientPhone = '',
  recipientName = '',
  addressLineOne = '',
  onAddressSelect = () => null,
  selected = false,
  nameLabel = 'Name',
  phoneLabel = 'Phone',
  name = 'AddressCard',
  addressId,
}: {
  addressId: number;
  selected?: boolean;
  areaID?: number;
  className?: string;
  recipientName?: string;
  recipientPhone?: string;
  addressLineOne?: string;
  onAddressSelect?: () => void;
  nameLabel?: string;
  phoneLabel?: string;
  name?: string;
}) => {
  return (
    <label className='fui-relative fui-block'>
      <input
        type='radio'
        name={name}
        value={addressId}
        className='fui-peer fui-invisible fui-absolute fui-opacity-0'
        onChange={onAddressSelect}
        defaultChecked={selected}
      />
      <div
        className={twMerge(
          'fui-relative fui-mb-4 fui-block fui-cursor-pointer fui-rounded-md fui-border fui-border-theme-neutral-200 fui-bg-white fui-p-2 fui-shadow-sm fui-transition-all fui-duration-150 hover:fui-border-theme-navy-900 hover:fui-shadow-button peer-checked:fui-border-theme-navy-900 peer-checked:fui-shadow-button',
          className
        )}
        onClick={onAddressSelect}
      >
        {selected ? (
          <IconTickRounded className='fui-absolute fui-top-2 fui-h-6 fui-w-6 fui-text-theme-navy-900 ltr:fui-left-auto ltr:fui-right-2 rtl:fui-left-2 rtl:fui-right-auto' />
        ) : (
          ''
        )}
        <div className='fui-flex fui-items-center fui-justify-start fui-px-2.5 fui-py-2 fui-align-middle'>
          <span className='fui-inline-flex fui-h-4 fui-w-4'>
            <IconMapMarker />
          </span>
          <span className='text-neutral-900 fui-px-2'>{areaID}</span>
        </div>
        <div className='fui-flex fui-items-center fui-justify-start fui-px-2.5 fui-py-0.5 fui-align-middle'>
          <p className='fui-block fui-text-md'>{addressLineOne}</p>
        </div>
        <div className='fui-flex fui-items-center fui-justify-start fui-px-2.5 fui-py-0.5 fui-align-middle'>
          <span className='fui-inline-flex fui-text-md'>{nameLabel} : </span>
          <span className='text-neutral-900 fui-px-2 fui-text-md'>
            {recipientName}
          </span>
        </div>
        <div className='fui-flex fui-items-center fui-justify-start fui-px-2.5 fui-py-0.5 fui-align-middle'>
          <span className='fui-inline-flex fui-text-md'>{phoneLabel} : </span>
          <span className='text-neutral-900 fui-dir-ltr fui-px-2 fui-text-md'>
            {recipientPhone}
          </span>
        </div>
      </div>
    </label>
  );
};
