import { PageTitle } from '@/components/PageTitle';
import { useLocalTranslator } from '@/hooks/useLocalTranslator';
import React from 'react';
import { useLocalApp } from '@/hooks/useLocalApp';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { getOrderTickets } from '@/api/order-issue.service';
import { TicketStatus } from '@/lib/helpers/constants';
import { ButtonLink } from '@flowardco/ui/Button';
import IconPlus from '@flowardco/fui-icons/src/IconPlus';
import { Snackbar } from '@flowardco/fui-snackbar';
import { ContentSkeleton } from '@flowardco/app-shared/components/SkeletonLoader';

const OrderIssue = () => {
  const { __T } = useLocalTranslator();
  const { appState } = useLocalApp();
  const { lang } = appState;
  const { data: orderTickets, isLoading } = useQuery(
    ['getOrderTickets', appState?.orderData?.order?.id],
    () => {
      return getOrderTickets(appState);
    }
  );
  const getTicketStatusLabel = (status: number) => {
    switch (status) {
      case TicketStatus.OPENED:
        return 'Opened';
      case TicketStatus.WORKING_ON:
        return 'Working on';
      case TicketStatus.CLOSED:
        return 'Closed';
      default:
        return 'Unknown';
    }
  };
  return (
    <>
      <PageTitle title={__T('Issue with my order')} showBackButton />
      {isLoading && <ContentSkeleton />}
      <div className='fui-mt-10px fui-bg-white fui-p-4'>
        {orderTickets?.length > 0 ? (
          <>
            {orderTickets?.map((ticket: any) => {
              return (
                <div
                  key={ticket?.id}
                  className='fui-mb-4 fui-rounded fui-border fui-border-navy-500 fui-bg-white fui-p-10px fui-shadow fui-shadow-gray-500'
                >
                  <h3 className='fui-mb-4 fui-leading-5 fui-text-navy-500'>
                    Subject: {ticket?.title}
                  </h3>
                  <p className='fui-mb-4 fui-leading-5 fui-text-green-850'>
                    issue: {ticket?.topicName}
                  </p>
                  <p className='fui-mb-4 fui-text-md fui-text-gray-750'>
                    {ticket?.description}
                  </p>
                  <p className='fui-mb-4 fui-leading-5 fui-text-navy-500'>
                    Ticket status: {getTicketStatusLabel(ticket?.status)}
                  </p>
                </div>
              );
            })}
          </>
        ) : (
          <>
            {!isLoading ? (
              <div>
                <Snackbar
                  noteText={__T("You don't have any previous issues")}
                />
              </div>
            ) : (
              ''
            )}
          </>
        )}
        <div className='fui-block fui-py-2'>
          <ButtonLink
            buttonStyle='secondary'
            size='sm'
            className='fui-w-full'
            renderAs={Link}
            renderElemProps={{ to: `/${lang}/issues/add-new` }}
          >
            <span className='fui-inline-flex fui-items-center fui-justify-center fui-px-0.5'>
              <IconPlus className='fui-w-5' strokeWidth={3} />
            </span>
            <span className='fui-inline-flex fui-items-center fui-justify-center fui-px-0.5'>
              {__T('raise a complaint')}
            </span>
          </ButtonLink>
        </div>
      </div>
    </>
  );
};

export default OrderIssue;
