export const COUNTRY_CODES = {
  '1': { code: 'kw', currency: 'KWD', decimalPoint: 3 },
  '2': { code: 'sa', currency: 'SAR', decimalPoint: 2 },
  '3': { code: 'om', currency: 'OMR', decimalPoint: 3 },
  '4': { code: 'qa', currency: 'QAR', decimalPoint: 2 },
  '5': { code: 'bh', currency: 'BHD', decimalPoint: 3 },
  '6': { code: 'ae', currency: 'AED', decimalPoint: 2 },
  '8': { code: 'gb', currency: 'GBP', decimalPoint: 2 },
  '13': { code: 'jo', currency: 'JOD', decimalPoint: 3 },
  '18': { code: 'eg', currency: 'EGP', decimalPoint: 2 },
};

export const OPS_LIST = [
  {
    countryId: 1,
    countryCode: 'KW',
    id: 1,
    lat: '29.116864',
    lng: '48.123553',
    titleAr: 'الكويت',
    titleEn: 'Kuwait',
  },
  {
    countryId: 2,
    countryCode: 'SA',
    id: 2,
    lat: '24.8131475',
    lng: '46.5987693',
    titleAr: 'الرياض',
    titleEn: 'Riyadh',
  },
  {
    countryId: 2,
    countryCode: 'SA',
    id: 3,
    lat: '21.586174',
    lng: '39.181732',
    titleAr: 'جدة',
    titleEn: 'Jeddah',
  },
  {
    countryId: 2,
    countryCode: 'SA',
    id: 6,
    lat: '21.413974',
    lng: '39.82336',
    titleAr: 'مكة',
    titleEn: 'Mecca',
  },
  {
    countryId: 2,
    countryCode: 'SA',
    id: 8,
    lat: '26.3855213',
    lng: '50.1453137',
    titleAr: 'الدمام',
    titleEn: 'dammam',
  },
  {
    countryId: 2,
    countryCode: 'SA',
    id: 11,
    lat: '26.3855213',
    lng: '50.1453137',
    titleAr: 'الإحساء',
    titleEn: 'Al Ahsa',
  },
  {
    countryId: 2,
    countryCode: 'SA',
    id: 12,
    lat: '26.3855213',
    lng: '50.1453137',
    titleAr: 'الجبيل',
    titleEn: 'Jubail',
  },
  {
    countryId: 2,
    countryCode: 'SA',
    id: 16,
    lat: '21.586174',
    lng: '39.181732',
    titleAr: 'المدينة المنورة',
    titleEn: 'Medina',
  },
  {
    countryId: 2,
    countryCode: 'SA',
    id: 26,
    lat: '24.813158',
    lng: '24.813158',
    titleAr: 'الخرج',
    titleEn: 'Al-Kharj',
  },
  {
    countryId: 2,
    countryCode: 'SA',
    id: 31,
    lat: '24.813158',
    lng: '24.813158',
    titleAr: 'أبها',
    titleEn: 'Abha',
  },
  {
    countryId: 2,
    countryCode: 'SA',
    id: 35,
    lat: '21.288861',
    lng: '40.426694',
    titleAr: 'الطائف',
    titleEn: 'Taif',
  },
  {
    countryId: 3,
    countryCode: 'OM',
    id: 19,
    lat: '23.5873184',
    lng: '58.4378862',
    titleAr: 'مسقط',
    titleEn: 'Muscat',
  },
  {
    countryId: 3,
    countryCode: 'OM',
    id: 27,
    lat: '23.5873184',
    lng: '23.5873184',
    titleAr: 'صحار',
    titleEn: 'Suhar',
  },
  {
    countryId: 3,
    countryCode: 'OM',
    id: 32,
    lat: '17.016995',
    lng: '54.092953',
    titleAr: 'صلالة',
    titleEn: 'Salalah',
  },
  {
    countryId: 4,
    countryCode: 'QA',
    id: 5,
    lat: '25.283943',
    lng: '51.3719109',
    titleAr: 'قطر',
    titleEn: 'Qatar',
  },
  {
    countryId: 5,
    countryCode: 'BH',
    id: 4,
    lat: '26.206338',
    lng: '50.5769373',
    titleAr: 'البحرين',
    titleEn: 'Bahrain',
  },
  {
    countryId: 6,
    countryCode: 'AE',
    id: 7,
    lat: '24.362434',
    lng: '54.4868213',
    titleAr: 'أبو ظبي',
    titleEn: 'Abu dhabi',
  },
  {
    countryId: 6,
    countryCode: 'AE',
    id: 9,
    lat: '24.362434',
    lng: '54.4868213',
    titleAr: 'العين',
    titleEn: 'Al Ain',
  },
  {
    countryId: 6,
    countryCode: 'AE',
    id: 13,
    lat: '25.095682',
    lng: '55.372875',
    titleAr: 'دبي',
    titleEn: 'dubai',
  },
  {
    countryId: 6,
    countryCode: 'AE',
    id: 14,
    lat: '24.9803392',
    lng: '55.1960566',
    titleAr: 'الشارقة',
    titleEn: 'Sharjah',
  },
  {
    countryId: 6,
    countryCode: 'AE',
    id: 15,
    lat: '24.9803392',
    lng: '55.1960566',
    titleAr: 'عجمان',
    titleEn: 'Ajman',
  },
  {
    countryId: 6,
    countryCode: 'AE',
    id: 17,
    lat: '24.9803392',
    lng: '55.1960566',
    titleAr: 'رأس الخيمة',
    titleEn: 'Ras Al-khaimah',
  },
  {
    countryId: 6,
    countryCode: 'AE',
    id: 24,
    lat: '24.9803392',
    lng: '24.9803392',
    titleAr: 'الفجيرة',
    titleEn: 'Fujairah',
  },
  {
    countryId: 6,
    countryCode: 'AE',
    id: 25,
    lat: '24.9803392',
    lng: '24.9803392',
    titleAr: 'ام القوين',
    titleEn: 'Umm Al Quwain',
  },
  {
    countryId: 8,
    countryCode: 'GB',
    id: 10,
    lat: '51.46477722826003',
    lng: '0.499219417038511',
    titleAr: 'لندن',
    titleEn: 'London',
  },
  {
    countryId: 13,
    countryCode: 'JO',
    id: 18,
    lat: '31.9414985',
    lng: '35.8343937',
    titleAr: 'عمان',
    titleEn: 'Amman',
  },
  {
    countryId: 13,
    countryCode: 'JO',
    id: 28,
    lat: '31.9414985',
    lng: '31.9414985',
    titleAr: 'السلط',
    titleEn: 'Al Salt',
  },
  {
    countryId: 13,
    countryCode: 'JO',
    id: 29,
    lat: '31.9414985',
    lng: '31.9414985',
    titleAr: 'الزرقاء',
    titleEn: 'Zarqa',
  },
  {
    countryId: 13,
    countryCode: 'JO',
    id: 30,
    lat: '31.9414985',
    lng: '31.9414985',
    titleAr: 'إربد',
    titleEn: 'Irbid',
  },
  {
    countryId: 13,
    countryCode: 'JO',
    id: 33,
    lat: '31.9414985',
    lng: '31.9414985',
    titleAr: 'مأدبة',
    titleEn: 'Madaba',
  },
  {
    countryId: 18,
    countryCode: 'EG',
    id: 20,
    lat: '30.1245841',
    lng: '31.3739018',
    titleAr: 'القاهرة',
    titleEn: 'Cairo',
  },
  {
    countryId: 18,
    countryCode: 'EG',
    id: 34,
    lat: '30.1245841',
    lng: '31.3739018',
    titleAr: 'الجيزة',
    titleEn: 'Giza',
  },
  {
    countryId: 18,
    countryCode: 'EG',
    id: 38,
    lat: '30.1245841',
    lng: '31.3739018',
    titleAr: 'الاسكندرية',
    titleEn: 'Alexandria',
  },
];
