import React, { useState } from 'react';
import cx from 'clsx';
import { Calendar } from '@flowardco/fui-calendar';
import {
  convertDateToYMD,
  convertDateToDMYWithMName,
  convertDateISOstart,
} from '@flowardco/flib-util';
import type { CalendarTileProperties } from 'react-calendar';

export interface DeliveryDateProps {
  onChange?(item: string, fromPicker?: boolean): void;
  className?: string;
  deliveryDates?: DeliveryDatesProps[];
  type?: string;
  __T: (key?: string) => string;
  labelClassName?: string;
  itemClassName?: string;
}
export interface DeliveryDatesProps {
  id: number;
  title: string;
  month: string;
  picker: boolean;
  date: string;
  day: number;
  disabledDays?: string[] | null;
}

export const DeliveryDate: React.FC<DeliveryDateProps> = ({
  className = '',
  deliveryDates = [],
  type = 'radio',
  onChange,
  __T,
  labelClassName = '',
  itemClassName = '',
}) => {
  const cssClass = cx(className, 'fui-flex fui-flex-wrap fui-mb-2 fui-w-full');
  const [showCalender, setShowCalender] = useState(false);
  const [selectedDate, setSelectedDate] = useState<{
    id: number;
    date: string;
  }>({
    id: deliveryDates[0]?.id,
    date: convertDateToYMD(new Date(deliveryDates[0]?.date)),
  });
  const handleDateClicked = (e: any, item: DeliveryDatesProps) => {
    if (!item.picker) {
      const transformedDate = convertDateToYMD(new Date(e.target.value));
      setSelectedDate({
        date: transformedDate,
        id: item.id,
      });
      if (onChange) {
        onChange(transformedDate, false);
      }
    } else {
      setSelectedDate({ date: selectedDate.date, id: item.id });
    }
  };

  const handleDateChange = (date: Date) => {
    const stringDate = convertDateToYMD(date);
    setSelectedDate({ ...selectedDate, date: stringDate });
    setShowCalender(false);
    if (onChange) {
      onChange(stringDate, true);
    }
  };
  const calendarDeliveryDates =
    deliveryDates?.find((itm) => itm.picker === true) || undefined;
  const handleDisableSpecificDates = (props: CalendarTileProperties) => {
    if (props.view === 'month' && calendarDeliveryDates?.disabledDays) {
      const findDisabledDate = calendarDeliveryDates.disabledDays.find(
        (dDate) => dDate === convertDateISOstart(props.date)
      );
      if (findDisabledDate) return true;
    }
    return false;
  };
  return (
    <>
      <div className={cssClass} data-testid='TestId__DeliveryDate'>
        {deliveryDates.length > 0 &&
          deliveryDates.map((item: any) => {
            const checked = item.id === selectedDate.id;
            const formattedDate = selectedDate.date
              ? convertDateToDMYWithMName(new Date(selectedDate.date))
              : null;
            return (
              <div
                key={item.id}
                className='fui-mb-2 fui-h-[100px] fui-w-1/3 fui-px-[7.5px]'
              >
                <div
                  data-testid='TestId__DeliveryDateItem'
                  onClick={() => {
                    setShowCalender(item.picker);
                  }}
                  className={cx(
                    'fui-relative fui-h-full fui-cursor-pointer fui-flex-col fui-rounded fui-border fui-transition-all fui-duration-200 hover:fui-shadow-flwBtn',
                    checked
                      ? 'fui-border-navy-500 fui-text-navy-500'
                      : 'fui-border-gray-310 fui-text-black-200',
                    itemClassName
                  )}
                >
                  <input
                    data-testid='TestId__DeliveryDateInput'
                    className={cx(
                      'fui-absolute fui-left-0 fui-top-0 fui-h-full fui-w-full fui-cursor-pointer fui-opacity-0'
                    )}
                    checked={checked}
                    type={type}
                    name={`date-${item.id}`}
                    id={`${item.id}-date`}
                    onChange={(e) => {
                      handleDateClicked(e, item);
                    }}
                    value={item.date}
                  />
                  <label
                    htmlFor={item.id}
                    className='fui-h-full  fui-w-full fui-rounded fui-p-1 fui-text-center '
                  >
                    {!item.picker ? (
                      <>
                        <span
                          className={cx(
                            'fui-block fui-text-sm',
                            labelClassName
                          )}
                        >
                          {item.title}
                        </span>
                        <span
                          className={cx(
                            'fui-block fui-text-sm',
                            labelClassName
                          )}
                        >
                          {item.day}
                        </span>
                        <span
                          className={cx(
                            'fui-block fui-text-sm',
                            labelClassName
                          )}
                        >
                          {item.month}
                        </span>
                      </>
                    ) : (
                      <>
                        <img
                          src='https://floward.imgix.net/web/Files/attachment/calender-637520521630113673.svg'
                          className='fui-mx-auto	fui-block fui-h-18px fui-w-18px fui-max-w-full'
                          alt='date'
                        />
                        <p className='fui-font-weight-bold fui-text-center fui-text-sm'>
                          {selectedDate.date && selectedDate.id === item.id ? (
                            <span
                              className={cx(
                                'fui-mt-2 fui-text-sm',
                                labelClassName
                              )}
                            >
                              {formattedDate}
                            </span>
                          ) : (
                            <b
                              className='fui-text-black-300'
                              data-testid='TestId__DeliveryDateTitle'
                            >
                              {__T('Pick a date')}
                            </b>
                          )}
                        </p>
                      </>
                    )}
                  </label>
                </div>
              </div>
            );
          })}
      </div>
      {showCalender && (
        <Calendar
          className='fui-w-full'
          onChange={handleDateChange}
          minDate={new Date()}
          tileDisabled={handleDisableSpecificDates}
          defaultValue={new Date(selectedDate.date)}
        />
      )}
    </>
  );
};
