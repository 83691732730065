import * as React from 'react';
import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

const SvgIconMapMarker = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox='0 0 16 22'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    ref={ref}
    {...props}
  >
    <path
      d='M8 .535C3.653.535.2 4.112.2 8.43c0 2.96.934 3.865 6.134 12.253a1.926 1.926 0 0 0 3.291 0c5.2-8.388 6.175-9.293 6.175-12.253C15.8 4.07 12.266.535 8 .535Zm0 19.079C2.8 11.226 2.15 10.691 2.15 8.43c0-3.249 2.6-5.921 5.85-5.921 3.21 0 5.85 2.672 5.85 5.92 0 2.262-.65 2.715-5.85 11.185ZM4.75 8.43c0 1.85 1.422 3.29 3.25 3.29 1.787 0 3.25-1.44 3.25-3.29 0-1.81-1.463-3.29-3.25-3.29-1.828 0-3.25 1.48-3.25 3.29Z'
      fill='currentColor'
    />
  </svg>
);

const ForwardRef = forwardRef(SvgIconMapMarker);
export default ForwardRef;
