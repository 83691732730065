import * as React from 'react';
import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

const SvgIconClock = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox='0 0 12 11'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    ref={ref}
    {...props}
  >
    <path
      d='M6 .333A5.166 5.166 0 0 0 .833 5.5 5.166 5.166 0 0 0 6 10.667 5.166 5.166 0 0 0 11.167 5.5 5.166 5.166 0 0 0 6 .333Zm0 9.334A4.152 4.152 0 0 1 1.833 5.5 4.166 4.166 0 0 1 6 1.333 4.179 4.179 0 0 1 10.167 5.5 4.166 4.166 0 0 1 6 9.667ZM7.27 7.5c.126.083.272.063.355-.062l.396-.521c.083-.125.062-.271-.063-.354L6.583 5.542V2.583a.269.269 0 0 0-.25-.25h-.666a.253.253 0 0 0-.25.25v3.438c0 .062.02.146.083.187L7.27 7.5Z'
      fill='currentColor'
    />
  </svg>
);

const ForwardRef = forwardRef(SvgIconClock);
export default ForwardRef;
