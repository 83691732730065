import * as React from 'react';
import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

const SvgIconWarning = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 16 16'
    fill='none'
    ref={ref}
    {...props}
  >
    <path
      d='M8 0.25C3.71875 0.25 0.25 3.75 0.25 8C0.25 12.2812 3.71875 15.75 8 15.75C12.25 15.75 15.75 12.2812 15.75 8C15.75 3.75 12.25 0.25 8 0.25ZM8 14.25C4.53125 14.25 1.75 11.4688 1.75 8C1.75 4.5625 4.53125 1.75 8 1.75C11.4375 1.75 14.25 4.5625 14.25 8C14.25 11.4688 11.4375 14.25 8 14.25ZM9.3125 11C9.3125 10.2812 8.71875 9.6875 8 9.6875C7.25 9.6875 6.6875 10.2812 6.6875 11C6.6875 11.75 7.25 12.3125 8 12.3125C8.71875 12.3125 9.3125 11.75 9.3125 11ZM6.75 4.40625L6.96875 8.65625C6.96875 8.84375 7.15625 9 7.34375 9H8.625C8.8125 9 9 8.84375 9 8.65625L9.21875 4.40625C9.21875 4.1875 9.0625 4 8.84375 4H7.125C6.90625 4 6.75 4.1875 6.75 4.40625Z'
      fill='currentColor'
    />
  </svg>
);

const ForwardRef = forwardRef(SvgIconWarning);
export default ForwardRef;
