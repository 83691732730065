import React from 'react';
import cx from 'clsx';

export interface IconHeadingProps {
  onClick?(event: React.MouseEvent): void;
  className?: string;
  icon?: React.ReactNode;
  title?: React.ReactNode;
  iconClass?: string;
  titleClass?: string;
}

export const IconHeading: React.FC<IconHeadingProps> = ({
  className = '',
  icon = '',
  title = '',
  iconClass,
  titleClass,
  onClick = () => null,
}) => {
  return (
    <h3
      className={cx(
        'fui-flex fui-w-full fui-flex-nowrap fui-items-center fui-justify-start fui-align-middle',
        className
      )}
      data-testid='TestId__IconHeadingWrapper'
      onClick={onClick}
    >
      {icon ? (
        <span
          className={cx(
            'fui-inline-flex fui-h-5 fui-w-5 fui-justify-center',
            iconClass
          )}
          data-testid='TestId__IconHeadingIcon'
        >
          {icon}
        </span>
      ) : (
        ''
      )}
      {title ? (
        <span
          className={cx(
            'fui-infline-flex fui-items-center fui-px-2 fui-align-middle fui-text-base fui-font-bold',
            titleClass
          )}
          data-testid='TestId__IconHeadingTitle'
        >
          {title}
        </span>
      ) : (
        ''
      )}
    </h3>
  );
};
