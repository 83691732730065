import * as React from 'react';
import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

const SvgIconHeadset = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    viewBox='0 0 22 22'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    ref={ref}
    {...props}
  >
    <path
      d='M3.017 16.06a2.081 2.081 0 0 0 1.564-.553 2 2 0 0 0 .546-1.154c.067-.79 0-1.807 0-2.816v-1.394c0-.516.045-1.022 0-1.42a1.973 1.973 0 0 0-2.184-1.637 1.942 1.942 0 0 0-1.736 1.877 2.011 2.011 0 0 0-.6.145c-.695.368-.571 1.262-.571 2.405a7.821 7.821 0 0 0 .049 1.9 1.216 1.216 0 0 0 1.117.77 1.92 1.92 0 0 0 1.815 1.877ZM19.913 8.193a2 2 0 0 0-2.035-1.108 1.94 1.94 0 0 0-1.713 1.661c-.074.75 0 1.775 0 2.814 0 1.018-.082 2.064 0 2.84.047.354.193.687.423.961.088.106.252.154.248.29a5.656 5.656 0 0 1-3.424 2.767 5.095 5.095 0 0 0-.595-.553 2.478 2.478 0 0 0-2.556.024 2.086 2.086 0 0 0-.918 1.732 2.229 2.229 0 0 0 4.392.481 7.262 7.262 0 0 0 4.887-4.113 1.85 1.85 0 0 0 1.439-1.83 1.125 1.125 0 0 0 1.143-.77c.062-.598.07-1.2.024-1.8a14.2 14.2 0 0 0 0-1.73 1.168 1.168 0 0 0-1.167-.89 1.807 1.807 0 0 0-.148-.776ZM15.941 1.867a8.839 8.839 0 0 0-11.164.242 7.969 7.969 0 0 0-2.483 3.734.462.462 0 0 0 .465.6c.284-.013.568.008.847.064a.471.471 0 0 0 .539-.3A6.346 6.346 0 0 1 6.29 3.282a6.8 6.8 0 0 1 8.411-.024 6.362 6.362 0 0 1 2.154 2.928.471.471 0 0 0 .535.291 2.98 2.98 0 0 1 .809-.044h.009a.465.465 0 0 0 .519-.588 8.352 8.352 0 0 0-2.786-3.978Z'
      fill='currentColor'
    />
  </svg>
);

const ForwardRef = forwardRef(SvgIconHeadset);
export default ForwardRef;
